<template>
    <div v-if="editProduct" ref="cardOfProduct">
        <div class="border p-3 rounded mt-1" :class="getBackgroundStyle" v-on-long-press="editingProduct">
            <div v-if="isDebugMode">
                Index: {{editProduct.sortIndex}}
            </div>
            <div class="d-flex justify-content-between drop-zone" >
                <div class="d-flex">
                    <div v-if="editProduct.isEditing" class="me-4" @click="removeProduct">
                        <i class="fa-solid fa-trash fa-lg"></i>
                    </div>
                    <span v-if="!editProduct.isEditOpen" @click="openChangeName" class="pointer" :class="getNameStyle">{{editProduct.name}}</span>
                    <span v-if="editProduct.isEditOpen">
                        <input @blur="changeName" @keyup.enter="changeName" class="form-control" v-model="editProduct.name" />
                    </span>
                </div>
                <div class="d-flex" v-if="(!isSaving&&!editProduct.isEditing)">
                    <div @click="showEditorName" v-if="editProduct.editor" class="me-3 pointer">{{cartProduct.editor.initials}}</div>               
                    <div v-if="!cartProduct.isBought" @click="wasBought(true)">
                        <i class="fa-regular fa-circle-check fa-lg"></i>
                        <!--<i class="fa-solid fa-cart-arrow-down fa-lg"></i>-->
                    </div>
                    <div v-if="cartProduct.isBought" @click="wasBought(false)">
                        <i class="fa-solid fa-cart-plus fa-lg"></i>
                    </div>
                </div>
                <div v-if="isSaving" :class="getNameStyle">
                    <span class="spinner-border spinner-border-sm"> </span>
                </div>
                <div v-if="editProduct.isEditing" class="d-flex">
                    <div class="me-2" @click="sortElement('down')">
                        <i class="fa-solid fa-arrow-down fa-lg"></i>
                    </div>
                    <div class="me-4" @click="sortElement('up')">
                        <i class="fa-solid fa-arrow-up fa-lg"></i>
                    </div>
                    <div @click="saveSort">
                        <i class="fa-solid fa-floppy-disk fa-lg"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CartProduct from '@/assets/model/CartProduct';
import confirmMessage from '@/assets/js/messageObjects/confirmMessageObject';
import { mapGetters } from 'vuex'
import FlashMessage from '@/assets/js/messageObjects/flashMessageObject';
import moment from 'moment';

export default {
    name: "CartProduct",
    emits: ['changeSortIndex', 'onSaveDb', 'onRemove'],
    data() {
        return {
            editProduct: null,
            isSaving: false
        }
    },
    created() {
        this.editProduct = this.cartProduct
        console.log(this.editProduct)
    },
    computed: {
        ...mapGetters({
            user: 'user/getUser',
            cartId: 'cart/getFocusCartId',
            cartList: 'cart/getList',
            mousePosition: 'system/getMousePosition',
            isDebugMode: 'localSetting/getDebugMode'
        }),
        getNameStyle() {
            if(this.cartProduct.isBought) {
                return "fst-italic text-decoration-line-through";
            }
            return "fw-bold"
        },
        getBackgroundStyle() {
            if(this.cartProduct.isBought) {
                return "";
            }
            return "bg-light text-dark"
        },
        cart() {
            if(this.cartId != null) {
                return this.cartList.find(obj => obj.id == this.cartId);
            }
            return null
        }
    },
    methods: {
        wasBought(value) {
            this.$emit('onSaveDb')
            this.isSaving = true
            this.cartProduct.setIsBought(value)
            this.cartProduct.saveInDb(this.$store,this.cart).then(() => {
               this.isSaving = false
            })
        },
        removeProduct() {
            const confrim = new confirmMessage(this.$store);
            confrim.newConfirmMessage('Bist du sicher, dass du "'+ this.editProduct.name + '" aus der Liste entfernen möchtest', () => {
                this.$emit('onRemove')
                this.editProduct.delete(this.$store, this.cart).then(() => {
                    
                })
            }, 'Ja, bitte löschen')

        },
        openChangeName() {
            this.editProduct.isEditOpen = true;
        },
        changeName() {
            this.$emit('onSaveDb')
            this.editProduct.isEditOpen = false;
            this.editProduct.saveInDb();   
        },
        showEditorName() {
            const message = new FlashMessage();
            
            message.newMessage('info','Zuletzt hat <b>'+ this.editProduct.editor.name + '</b> am <b>'+ moment(this.editProduct.editedAt).format('LL') +'</b> um  <b>' + moment(this.editProduct.editedAt).format('HH:mm') + ' Uhr </b> den Artikel bearbeitet.')
            message.run(this.$store)
        },
        editingProduct() {
            this.editProduct.isEditing = true
        },
        sortElement(direction) {
            this.$emit('changeSortIndex', this.cartProduct, direction)
        },
        saveSort() {
            this.editProduct.isEditing = false
        }
    },
    props: {
        cartProduct: CartProduct
    }
}
</script>