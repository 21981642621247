//const uuid = require('uuid');
import apiConnection from "@/assets/js/apiConnection"
import helper from "../js/helper";
import CartProduct from "./CartProduct";


class CartList {
    
    constructor() {
        this.name = ""
        this.id = null
        this.cartProducts = []
        this.community = ""
        this.description = ""
        this.generalCosts = 0
        this.sumCosts = 0
        this.type = "/api/cart_list_types/1"
    }

    setName(name=undefined) {
        if(name != undefined) {
            this.name = name;
        }
        return this.name;
    }

    getName() {
        return this.name;
    }

    /**
     * 
     */
    setCommunity(community) {
        this.community = helper.getIri(community, '/api/communities/')
        return this.community
    }

    setDescription(value) {
        this.description = value;
    }

    setGeneralCosts(value) {
        this.generalCosts = value
        this.calculateCosts();
    }

    /**
     * 
     * @param {CartListType} type 
     * @returns {CartListType}
     */
    setType(type) {
        if(type == undefined) {
            return
        }
        this.type = helper.getIri(type, '/api/cart_list_types/')
        return this.type
    }

    openTransportLayer(data) {
        this.name = data.name;
        this.id = data.id
        for(const product of data.cartProducts) {
            this.cartProducts.push(new CartProduct(product, this))
        }
        if(data.description) {
            this.description = data.description
        }
        if(data.generalCosts) {
            this.generalCosts = data.generalCosts
        }
        this.setCommunity(data.community)
        this.setType(data.cartListType)
        this.calculateCosts()
    }

    /**
     * 
     * @param {CartProduct} cartProduct 
     */
    addCartProduct(cartProduct) {
        this.cartProducts.push(cartProduct)
        this.calculateCosts()
    }

    removeCartProduct(cartProduct) {
        let index = this.cartProducts.findIndex(obj => obj == cartProduct);
        this.cartProducts.splice(index,1);
        this.calculateCosts()
    }

    calculateCosts() {
        let value = 0
        for(const product of this.cartProducts) {
            value += parseFloat( product.value )
        }
        value += parseFloat(this.generalCosts)
        this.sumCosts = value
        return value
    }

    getTransportLayer() {
        const data = {
            name: this.name,
            '@id': '/api/cart_lists/'+this.id,
            //cartProducts: [],
            description: this.description,
            community: this.community,
            generalCosts: this.generalCosts.toString(),
            cartListType: this.type
        }
        console.log(data)
        return data
    }

    /**
     * 
     * @returns {Promise<AxiosResponse<any, any>>}
     */
    saveInDb() {
        let request;
        if(this.id === null || this.id === undefined) {
            request = apiConnection.post('/api/cart_lists', this.getTransportLayer());
        } else {
            request = apiConnection.patch('/api/cart_lists/'+this.id, this.getTransportLayer());
        }
        request.then((response) => {
            this.openTransportLayer(response.data)
        })
        return request
    }

    saveDB() {
        let request;
        if(this.id === null || this.id === undefined) {
            request = apiConnection.post('/api/cart_lists', this.getTransportLayer());
        } else {
            request = apiConnection.patch('/api/cart_lists/'+this.id, this.getTransportLayer());
        }
        return request
    }

    delete(store = null) {
        if(store != null) {
            store.dispatch('cart/deleteCart', this)
        }
        let request = apiConnection.delete('/api/cart_lists/'+this.id);
        return request;
    }
}


export default CartList