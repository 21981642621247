<template>
    <h1 @click="updateData" >HomeView</h1>
    <div v-if="user">
        <span v-if="user">Hallo {{user.name}},</span>
        <community-invitation-message class="mt-3" />
        
        <div class="row ms-1 me-1 mt-4 text-center">
            <div class="col-6 p-1">
                <div @click="goTo('changeLog')" class="card p-0 border-dark pointer">
                    <div class="card-header bg-dark">Changelog</div>
                    <div class="card-image">
                        <img  class="img-fluid cardHeight" src="@/assets/files/system/update.jpg" />
                    </div>
                </div>
            </div>
            <div class="col-6 p-1">
                <div @click="goTo('settings')" class="card p-0 border-dark pointer">
                    <div class="card-header bg-dark">Einstellung</div>
                    <div class="card-image">
                        <img  class="img-fluid cardHeight" src="@/assets/files/system/mixer.jpg" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="!user">
        <button @click="goToLogin" class="btn btn-outline-primary w-100">Hier einloggen</button>
    </div>

</template>

<script>
import CommunityInvitationMessage from '@/components/community/CommunityInvitationMessage.vue';
import { mapGetters } from 'vuex'
export default {
    created() {
        this.checkIsUserLoggedIn();
        //this.$router.push({name: 'login'})
        this.updateData();
    },
    computed: {
        ...mapGetters({
            user: "user/getUser"
        })
    },
    methods: {
        updateData() {
            //this.$store.dispatch('system/startLoadingProcess')
        },
        goTo(name) {
            this.$router.push({ name: name });
        },
        checkIsUserLoggedIn() {
            this.$store.dispatch("user/getUser").then(() => {
                if (this.user == undefined || this.user == null) {
                    this.goToLogin();
                }
            });
        },
        goToLogin() {
            this.$router.push({ name: "login" });
        }
    },
    components: { CommunityInvitationMessage }
}
</script>