<template>
    <h1>Finanzen</h1>
    <div id="ActionBar" class="d-flex justify-content-between mb-3">
        <input @keydown.enter="addTransaction" class="form-control" v-model="newTransactionName" placeholder="Neue Transaktion" />
        <button @click="addTransaction" class="btn btn-primary ms-1">Hinzufügen</button>
    </div>

    <div :key="index" v-for="(cart, index) of cartListItems">
        <div class="card mt-2" @click="openTransaction(cart)">
            <div class="card-header">
                {{ cart.name }}
            </div>
            <div class="card-body text-dark">
                <div v-if="cart.cartProducts.length">Positionen: {{ cart.cartProducts.length }}</div>
                <div v-if="cart.generalCosts">Allgemeinkosten: {{ cart.sumCosts }} €</div>
            </div>
        </div>
    </div>

</template>

<script>
import CartList from '@/assets/model/CartList';
import { mapGetters } from 'vuex';



export default {
    name: "FinanceDashboard",
    data() {
        return {
            newTransactionName: ""
        }
    },
    created() {
        this.$store.dispatch('community/getAll');
        this.$store.dispatch('cart/getCartListTypes');
        this.community = this.$store.dispatch('community/changeFocus');
        console.log(this.community)
    },
    methods: {
        addTransaction() {
            let cartList = new CartList();

            cartList.setName(this.newTransactionName)
            cartList.setType(this.cartTypes[1])
            cartList.setCommunity(this.community)

            cartList.saveInDb().then((response) => {
                this.$router.push({name: "financeTransaction", params: {
                    id: response.data.id
                }})
            })

        },
        openTransaction(cart) {
            this.$router.push({name: "financeTransaction", params: {
                id: cart.id
            }}) 
        }
    },
    computed: {
        ...mapGetters({
            'cartListItems': 'cart/getList',
            'cartTypes': 'cart/getCartListTypes',
            'community': 'community/getFocus',
        })
    }
}
</script>