<template>
  <h1>Einstellung</h1>

    
    <div class="p-2">
      <button @click="goToProfile" class="btn btn-outline-primary w-100"><i class="fa-solid fa-user fa-lg"></i> Dein Profile</button>
      <div class="mt-4">
        <h5>Automatischer Preisimport</h5>
        <p class="mb-0">Möchtest du, dass beim Klick auf das Tankformular direkt nach deinem aktuellen Standort gesucht wird und der Preis der nächsten Tankstelle automatisch importiert wird? Es spart beim Tankvorgang viel Zeit.</p>
        <div class="d-flex" style="font-size:1.5em" >
          <span class="me-3">Nein</span>
          <span @click="setAutoPriceFuelImport(false)" v-if="autoPriceFuelImport"><i class="fa-solid fa-toggle-on"></i></span>
          <span @click="setAutoPriceFuelImport(true)" v-if="!autoPriceFuelImport"><i class="fa-solid fa-toggle-off"></i></span>
          <span class="ms-3">Ja</span>
        </div>
        <hr>
        <h5>Debug Modus</h5>
        <p class="mb-0">Zeigt einige Entwicklungsinformationen an</p>
        <div class="d-flex" style="font-size:1.5em" >
          <span class="me-3">Nein</span>
          <span @click="setDebugMode(false)" v-if="isDebugMode"><i class="fa-solid fa-toggle-on"></i></span>
          <span @click="setDebugMode(true)" v-if="!isDebugMode"><i class="fa-solid fa-toggle-off"></i></span>
          <span class="ms-3">Ja</span>
        </div>
      </div>


      <button @click="logout" class="btn w-100 btn-danger mt-5"><b>Ausloggen</b></button>
    </div>

</template>

<script>
import FlashMessage from '@/assets/js/messageObjects/flashMessageObject'
import { mapGetters } from 'vuex'
export default {
    name: "SettingsWindow",
    data() {
      return {
        showSaveButton: false
      }
    },
    created() {
      this.$store.dispatch('user/getUser').then(() =>  {
      })
      
    },
    methods: {
      logout() {
        this.$store.dispatch('user/logout').then(() => {
          this.$router.push({name: 'login'})
        })
      },
      goToProfile() {
        this.$router.push({name: 'profile'})
      },
      setAutoPriceFuelImport(value) {
        this.$store.dispatch('localSetting/setAutoFuelPriceImport', value)
        let message = new FlashMessage();
        message.newMessage('success', 'Einstellung erfolgreich geändert')
        message.run(this.$store)
      },
      setDebugMode(value) {
        this.$store.dispatch('localSetting/setDebugMode', value)
        let message = new FlashMessage();
        message.newMessage('success', 'Einstellung erfolgreich geändert')
        message.run(this.$store)
      }
    },
    computed: {
      ...mapGetters({
        user: 'user/getUser',
        autoPriceFuelImport: 'localSetting/getAutoFuelPriceImport',
        isDebugMode: 'localSetting/getDebugMode'
      })
    }
}
</script>

<style>

</style>