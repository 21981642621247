<template>
    <div  @click="setDisable" class="dgModal">
        <div @click.stop class="card dgModal-content">
            <div class="card-header text-center">
                <h3>Neues Fahrzeug</h3>
            </div>
            <div class="card-body text-black">
                <communication-error v-if="communicationError" :error="communicationError" />
                <div class="mb-2">
                        <label for="email" class="form-label">Name</label>
                        <input @keydown.enter="addNew" v-model="editVehicle.name" class="form-control" type="text" />
                        <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                </div>
                <div class="mb-2">
                        <label for="firstname" class="form-label">Kennzeichen</label>
                        <input @keydown.enter="addNew" v-model='editVehicle.licensePlate' class="form-control" type="text" />
                        <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                </div>
                <button @click="addNew" for="save" class="form-control mt-5 btn btn-success">Speichern</button>
            </div>
        </div>
    </div>
</template>

<script>
import CommunicationError from '../general/CommunicationError.vue';
import { mapGetters } from 'vuex';
import FlashMessage from '@/assets/js/messageObjects/flashMessageObject';

export default {
data() {
    return {
        editVehicle: {},
        communicationError: null
    }
},
created() {
    if(this.user == null) {
        this.$store.dispatch('user/getUser').then(() => {
            this.reset();
        }) ;
    } else {
        this.reset();
    }

},
methods: {
    setDisable() {
        this.$emit('setDisable');
    },
    addNew() {
        const flash = new FlashMessage();
        this.$store.dispatch('vehicle/addVehicle',this.editVehicle).then((response) => {
            if(response.status == 201) {
                flash.newMessage('success', 'Neues Fahrzeug hinzugefügt.')
                flash.run(this.$store);
                this.setDisable();
                this.reset();
            }
        }).catch((error) => {
            flash.newMessage('danger', 'Oh nein, da scheint etwas schief gelaufen zu sein.')
            flash.run(this.$store);
            this.communicationError = error
        }) 
        },
        reset() {
            this.editVehicle = {
                owner: 'api/users/'+this.user.id
            };
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/getUser'
        })
    },
    components: {
        CommunicationError
    }
}
</script>