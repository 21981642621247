import { createStore } from 'vuex'
import user from './user'
import vehicle from './vehicle'
import system from './system'
import file from './file'
import fuelprice from './fuelprice'
import community from './community'
import cart from './cart'
import localSetting from './system/localSetting'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    system,
    vehicle,
    file,
    fuelprice,
    community,
    cart,
    localSetting
  }
})
