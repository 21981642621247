//const uuid = require('uuid');
import apiConnection from "@/assets/js/apiConnection"
import moment from "moment";
import helper from "../js/helper";
import CartList from "./CartList";
//import store from "../../store"

CartList

class CartProduct {
    
    constructor(data = null, cartList = null) {
        this.name = ""
        this.id = null
        this.amount = 0.0
        this.value = 0.0
        this.sortIndex = 1
        this.cartList = ""
        this.isBought = false
        this.editor = null,
        this.createdAt = ''
        this.editedAt = ''
        this.isEditing = false
        this.sumPrice = 0.0
        
        /**
         * @type {boolean} Beschreibt ob der Betrag minus oder plus ist
         */
        this.invest = true
        if(cartList != null ) {
            this.setCartList(cartList)
        }
        if(data != null) {
            this.openTransportLayer(data);
        }
    }

    setName(name=undefined) {
        if(name != undefined) {
            this.name = name;
        }
        return this.name;
    }

    getName() {
        return this.name;
    }

    setAmount(amount) {
        this.amount = amount
        this.getSumPrice()
        return this.amount 
    }

    setValue(value) {
        this.value = value
        this.getSumPrice()
        return this.value 
    }

    setAsIncome() {
        if(this.value < 0) {
            this.value = this.value *-1;
        }
    }

    setAsOutcome() {
        if(this.value > 0) {
            this.value = this.value *-1;
        }
    }

    /**
     * 
     * Berechnet und gibt den kommulierten Preis zurück
     * @param {Number} decimalNumbers Viele Stellen hintern kommer
     * @returns {float}
     */
    getSumPrice(decimalNumbers = 2) {
        if (!this.value || !this.amount) {
            this.sumPrice = 0.0;
        }
        this.sumPrice = this.value * this.amount;
        return (this.sumPrice).toFixed(decimalNumbers)
    }

    setIsBought(value) {
        this.isBought = value
    }

    /**
     * 
     * @param {CartList} cartList 
     * @returns 
     */
    setCartList(cartList) {
        if(cartList == undefined) {
            console.error('CartList setzen schlug fehl. Es ist undefiniert.')
        }
        this.cartList = helper.getIri(cartList, '/api/cart_lists/')
        return this.cartList
    }

    openTransportLayer(data) {
        moment.locale('de')
        this.name = data.name;
        this.id = data.id
        this.cartProducts = data.cartProducts
        if(data.cart != undefined) {
            this.setCartList(data.cart)

        }
        this.value = data.value
        if(data.value == undefined) {
            this.value = 0.0
        } 
        this.sortIndex = data.sortIndex
        this.isBought = data.isBought
        this.editor = data.editor
        this.amount = data.amount
        if(data.amount == undefined) {
            this.amount = 0
        } 
        this.createdAt = moment(data.createdAt).format()
        this.editedAt = moment(data.editedAt).format()
    }

    getTransportLayer() {
        const data = {
            name: this.name,
            '@id': '/api/cart_products/'+this.id,
            cart: this.cartList,
            value: this.value.toString(),
            amount: this.amount.toString(),
            isBought: this.isBought,
            sortIndex: this.sortIndex
        }
        return data
    }

    saveInDb(store, cartList) {
        if(store != null) {
            let index = cartList.cartProducts.findIndex(obj => obj.id == this.id)
            cartList.cartProducts[index] = this;
            store.dispatch('cart/updateCart', cartList)
        }
        let request;
        if(this.id === null) {
            request = apiConnection.post('/api/cart_products', this.getTransportLayer());
        } else {
            request = apiConnection.patch('/api/cart_products/'+this.id, this.getTransportLayer());
        }
        request.then((response) => {
            this.openTransportLayer(response.data)
        })
        return request
    }

    saveDB() {
        let request;
        if(this.id === null) {
            request = apiConnection.post('/api/cart_products', this.getTransportLayer());
        } else {
            request = apiConnection.patch('/api/cart_products/'+this.id, this.getTransportLayer());
        }
        return request
    }

    delete(store, cartList) {
        if(store != null) {
            let index = cartList.cartProducts.findIndex(obj => obj.id == this.id)
            cartList.cartProducts.splice(index, 1);
            store.dispatch('cart/updateCart', cartList)
        }
        let request = apiConnection.delete('/api/cart_products/'+this.id)
        return request
    }
}


export default CartProduct