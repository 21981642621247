import store from "@/store"

class ButtonItem {
    buttonName = "Button"
    type = "button"
    buttonAction = () => {}
    textStyle = ""
    closeAfterClick = true
    viewObject = null
    viewValue = ''
    show = true
}

class ActionDialog {
    enabled = false;
    headerText = "Menü"
    buttonItems = []
    event = {}

    /**
     * 
     * @param {*} event Bitte das Event vom @Click hinzufügen 
     */
    constructor(event) {
        console.log(event)
        this.event = event
    }

    setHeaderText(value) {
        this.headerText = value
    }

    /**
     * 
     * @returns {ButtonItem}
     */
    getLastButton() {
        return this.buttonItems[this.buttonItems.length - 1];
    }

    addButton(buttonText, action, cssClass = "") {
        const buttonElement = new ButtonItem();
        buttonElement.buttonName = buttonText
        buttonElement.buttonAction = action;
        buttonElement.textStyle = cssClass
        this.buttonItems.push(buttonElement)
    }

    addTopic(buttonText, cssClass = "", viewObject, viewValue) {
        const buttonElement = new ButtonItem();
        buttonElement.buttonName = buttonText
        buttonElement.textStyle = cssClass
        buttonElement.type = "topic"
        buttonElement.viewObject = viewObject
        buttonElement.viewValue = viewValue
        this.buttonItems.push(buttonElement)
        return buttonElement
    }

    addHr(cssClass = "") {
        const buttonElement = new ButtonItem();
        buttonElement.type = "hr"
        buttonElement.textStyle = cssClass
        this.buttonItems.push(buttonElement)
        return buttonElement
    }

    /**
     * 
     * @param {boolean} value 
     */
    setCloseAfterClick(value = false) {
        this.getLastButton().closeAfterClick = value;
    }

    setButtonCondition(object) {
        this.getLastButton().show = object
    }

    run() {
        this.enabled = true;
        store.dispatch('system/setActionDialog', this);
    }

}

export default ActionDialog;