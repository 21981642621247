<template>
    <h1>Passwort ändern</h1>
    <div autocomplete="off" class="formStyle">
        <div v-if="error" class="alert alert-danger">
           {{errorMessage}}
        </div>
        <communication-error v-if="communicationError" :error="communicationError" />
        <div v-if="false&&!isAdmin" class="mb-2">
            <label for="oldPassword" class="form-label">Alte Passwort</label>
            <input v-model="passwords.old" class="form-control" type="password"  />
            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-2">
            <label for="newPassword" class="form-label">Neues Passwort</label>
            <input v-model="passwords.new" class="form-control" type="password"  autocomplete="off" />
            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-2">
            <label for="repeatPassword" class="form-label">Passwort wiederholen</label>
            <input v-model="passwords.repeat" class="form-control" type="password"  autocomplete="off"  />
            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <button for="save" @click="goToProfile" class="form-control mt-5 btn btn-outline-warning">Abbrechen</button>
        <button for="save" @click="savePassword" class="form-control mt-1 btn btn-success"><span v-show="isSaving" class="spinner-border spinner-border-sm text-white"></span> Speichern</button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CommunicationError from '@/components/general/CommunicationError.vue'
import flashMessageObject from '@/assets/js/messageObjects/flashMessageObject'

export default {
    data() {
        return {
            passwords: {},
            isSaving: false,
            error: false,
            errorMessage: "",
            communicationError: null
        }   
    },
    created() {
        this.$store.dispatch('user/getUser')
    },
    methods: {
        savePassword() {
            this.isSaving = true;
            this.error = false;
            let user = {}
            if(this.passwords.new == "" || this.passwords.new == undefined) {
                this.error = true;
                this.isSaving = false;
                this.errorMessage = "Kein Passwort eingegeben"
                return
            }
            if(this.passwords.new.length < 8) {
                this.error = true;
                this.isSaving = false;
                this.errorMessage = "Das Passwort muss mindestens 8 Zeichen betragen"
                return
            }
            if(this.passwords.new != this.passwords.repeat) {
                this.error = true;
                this.errorMessage = "Die Passwörter stimmen nicht überein."
                this.isSaving = false
                return
            }
            user['@id'] ="/api/users/"+this.userSelect.id;
            user.id = this.userSelect.id;
            user.password = this.passwords.new;
            this.$store.dispatch('user/saveUser',user).then(() => {
                setTimeout(() => {
                    flashMessageObject.newMessage('success', "Passwort erfolgreich geändert");
                    flashMessageObject.run(this.$store);
                    this.isSaving = false
                    this.goToProfile();
                }, 100)
            }).catch((error) => {
                this.communicationError = error;
            }) ;
        },
        goToProfile() {
            this.$router.push({name: 'profile-userid', params: {userId: this.userSelect.id}})
        }
    },
    computed: {
        ...mapGetters({
            userSelect: 'user/getUserSelect',
            user: 'user/getUser',
            isAdmin: 'user/isAdmin'
        })
    },
    components: {
        CommunicationError
    }

}
</script>