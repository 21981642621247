<template>
    <h1>Profile</h1>
    <communication-error v-if="communicationError" :error="communicationError" />
        <div class="formStyle">
        <div class="mb-2">
            <label for="email" class="form-label">Email Adresse</label>
            <input v-model="editUser.email" class="form-control" type="text" />
            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-2">
            <label for="firstname" class="form-label">Vorname</label>
            <input v-model='editUser.firstname' class="form-control" type="text" />
            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-2">
            <label for="firstname" class="form-label">Nachname</label>
            <input v-model='editUser.lastname' class="form-control" type="text" />
            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-2">
            <label for="firstname" class="form-label">Passwort</label>
            <button @click="goToChangePassword" for="firstname" class="form-control btn btn-outline-primary">Ändern</button>
            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-2" v-if="isAdmin">
            <label for="firstname" class="form-label">Rolle</label>
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="ROLE_ADMIN" v-model="editUser.roles" id="adminBox">
                <label class="form-check-label" for="adminBox">
                    Admin
                </label>
            </div>
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="VEHICLE_MANAGER_USER" v-model="editUser.roles" id="vmUser">
                <label class="form-check-label" for="vmUser">
                    Vehicle Manager User
                </label>
            </div>
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="VEHICLE_MANAGER_ADMIN" v-model="editUser.roles" id="vmAdmin">
                <label class="form-check-label" for="vmAdmin">
                    Vehicle Manager Admin
                </label>
            </div>
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="DUEMPEL_MANAGER_USER" v-model="editUser.roles" id="duempelUser">
                <label class="form-check-label" for="duempelUser">
                    Dümpel Manager User
                </label>
            </div>   
            <div class="form-check">
            <input class="form-check-input" type="checkbox" value="DUEMPEL_MANAGER_ADMIN" v-model="editUser.roles" id="duempelAdmin">
                <label class="form-check-label" for="duempelAdmin">
                    Dümpel Manager Admin
                </label>
            </div>   
        </div>
        <button @click="save" for="save" class="form-control mt-5 btn btn-success"><span v-show="isSaving" class="spinner-border spinner-border-sm text-white"></span> Speichern</button>
        <button v-if="isAdmin" @click="removeUser" for="delete" class="form-control mt-1 btn btn-outline-danger">Löschen</button>

    </div>
    <div v-if="isAdmin" class="formStyle mt-3">
        <button @click="goToUserlist" for="firstname" class="form-control mt-5 btn btn-primary">Userliste</button>
    </div>
</template>

<script>
import CommunicationError from '@/components/general/CommunicationError.vue'
import FlashMessage from '@/assets/js/messageObjects/flashMessageObject'
import { mapGetters } from 'vuex'

export default {
    name: "ProfileView",
    data() {
        return {
            editUser: {},
            idTest: 0,
            isSaving: false,
            communicationError: null
        }
    },
    created() {
        if(this.user == null) {
            this.$store.dispatch('user/getUser').then(() => {
                this.init()
            }) 
        } else {
            this.init();
        }
        },
    methods: {
        init() {
            let editUserId = this.user.id
            if(this.$route.params.userId != undefined) {
                editUserId = this.$route.params.userId 
            }
            this.$store.dispatch('user/getUserSelect',editUserId).then(() => {
                this.editUser = this.userSelect
            })
        },
        goToChangePassword() {
            this.$router.push({name: 'passwordChange', params: {userId: this.editUser.id}});
        },
        save() {
            this.isSaving = true;
            this.$store.dispatch('user/saveUser', this.editUser ).then(() => {
                this.isSaving = false;
            })
        },        
        goToUserlist() {
            this.$router.push({name: 'userList'})
        },
        removeUser() {
            const flash = new FlashMessage();
            let dialog = {};
            dialog.message = "Bist du sicher, dass du " + this.userSelect.name +  " entfernen möchtest?";
            dialog.confirmText = "Ja, bitte löschen";
            dialog.cancelText = "Abbruch";
            dialog.confirmFunction = () => {
                this.$store.dispatch('user/removeUser', this.userSelect.id ).then(() => {
                    flash.newMessage('success', "Nutzer wurde erfolgreich gelöscht.");
                    flash.run(this.$store);
                    this.$router.push({name: 'userList'})
                }).catch((error) => {
                    this.communicationError = error;
                })
            }
            this.$store.dispatch('system/startNewConfirm', dialog )
        }
    },
    computed: {
        ...mapGetters({
            userSelect: 'user/getUserSelect',
            user: 'user/getUser',
            isAdmin: 'user/isAdmin'
        })
    },
    components: {
        CommunicationError
    }
    
}
</script>