<template>
    <ConfirmDialog />
    <ApiMonitor v-if="isDebugMode" />
    <InfoBoxModal />
    <ActionDialog :enabled="actionDialogContent.enabled" :params="actionDialogContent" />
    <loading-bar v-if="isLoading" />
    <div class="container ps-0 pe-0 mb-5">
        <router-view >
            
        </router-view>
    </div>
   <FlashMessengerHolder />
   <div class="dummyPlace"></div>
   
   <div class="versionFooter mt-5 text-center">Version App: {{packageJson.version}} | API: {{serverStatus.version}}</div>
   <NavFooter v-if="!isLoading && isUserLoggedIn" />
</template>

<script>
import FlashMessengerHolder from './components/general/FlashMessengerHolder.vue';
import NavFooter from "./components/structure/NavFooter.vue";
import ConfirmDialog from "./components/general/ConfirmDialog.vue";
import packageJson from "../package.json"
import LoadingBar from './components/general/LoadingBar.vue';
import InfoBoxModal from './components/general/InfoBoxModal.vue';
import ApiMonitor from './components/general/ApiMonitor.vue';
import { mapGetters } from 'vuex';
import ActionDialog from './components/general/ActionDialog.vue';

export default {
    data() {
        return {
            packageJson: packageJson
        }
    },
    created() {
        this.updateData();
    },
    mounted() {
        document.addEventListener('mousemove', this.onMouseMove)
    },
    methods: {
        updateData() {
            this.$store.dispatch('system/setIsLoading', true)
            this.$store.dispatch('localSetting/load')
            this.$store.dispatch('user/getUser').then(() => {
                this.$store.dispatch('system/setIsLoading', false)
                this.$store.dispatch('community/getAll')
            }) 
            //this.$store.dispatch('system/startLoadingProcess')
        },
        onMouseMove(event) {
            let pos = {
                x: event.clientX,
                y: event.clientY
            }
            this.$store.dispatch('system/setMousePosition', pos)
        }

    },
    computed: {
        ...mapGetters({
            'isLoading': 'system/getIsLoading',
            'isUserLoggedIn': 'user/isUserLoggedIn',
            'serverStatus': 'system/getServerStatus',
            'isDebugMode': 'localSetting/getDebugMode',
            'actionDialogContent': 'system/getActionDialog'
        })
    },
    components: {
    NavFooter,
    FlashMessengerHolder,
    ConfirmDialog,
    LoadingBar,
    InfoBoxModal,
    ApiMonitor,
    ActionDialog
}
}
</script>

<style lang="scss" scoped>
    .dummyPlace {
        height: 100px;
    }

    .versionFooter {
        margin-top: 300px;
        margin-bottom: 100px;
        
} 
</style>