import { createRouter, /* createWebHashHistory */ createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/user/LoginView.vue'
import Settings from '../views/system/Settings.vue'
import ChangeLog from '../views/system/ChangeLog.vue'
import Profile from '../views/user/Profile.vue'
import UserList from '../views/user/UserList.vue'
import PasswordChange from '../views/user/PasswordChange.vue'
import VehicleList from '../views/vehicle/VehicleList.vue'
import VehicleEdit from '../views/vehicle/VehicleEdit.vue'
import VehicleTransaction from '../views/vehicle/Transaction.vue'
import VehicleDashboard from '../views/vehicle/Dashboard.vue'
import RefuelForm from '../views/vehicle/RefuelForm.vue'
import TransactionForm from '../views/vehicle/TransactionForm.vue'
import VehicleAnalyse from '../views/vehicle/Analyse.vue'
import CommunityList from '../views/community/CommunityList.vue'
import CartList from '../views/cart/CartList.vue'
import FinanceDashboard from '../views/finance/Dashboard.vue'
import FinanceTransaction from '../views/finance/Transaction.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings
  },
  {
    path: '/changelog',
    name: 'changeLog',
    component: ChangeLog
  },
  {
    path: '/profile/:userId',
    name: 'profile-userid',
    component: Profile
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/userList',
    name: 'userList',
    component: UserList
  },
  {
    path: '/passwordChange/:userId',
    name: 'passwordChange',
    component: PasswordChange
  },
  {
    path: '/vehicleList/',
    name: 'vehicleList',
    component: VehicleList
  },
  {
    path: '/vehicleEdit/:vehicleId',
    name: 'vehicleEdit',
    component: VehicleEdit
  },
  {
    path: '/vehicleTransaction/:vehicleId',
    name: 'vehicleTransaction',
    component: VehicleTransaction
  },
  {
    path: '/vehicleDashboard',
    name: 'vehicleDashboard',
    component: VehicleDashboard
  },
  {
    path: '/refuelForm',
    name: 'refuelForm',
    component: RefuelForm
  },
  {
    path: '/transactionForm/:transactionId?/:categoryId?',
    name: 'transactionForm',
    component: TransactionForm
  },
  {
    path: '/vehicleAnalyse',
    name: 'vehicleAnalyse',
    component: VehicleAnalyse
  },
  {
    path: '/communityList',
    name: 'communityList',
    component: CommunityList
  },
  {
    path: '/cartList',
    name: 'cartList',
    component: CartList
  },
  {
    path: '/financeDashboard',
    name: 'financeDashboard',
    component: FinanceDashboard
  },
  {
    path: '/financeTransaction/:id?',
    name: 'financeTransaction',
    component: FinanceTransaction
  }
  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
