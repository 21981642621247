const uuid = require('uuid')

const state = {
    flashMessageItems: [],
    confirmDialog: [],
    infoDialog: [],
    isLoading: false,
    serverStatus: {},
    mousePosition: {},
    apiMonitorData: {}
    /*movingObject: {}*/,
    actionDialog: {
        enabled: false
    }
}

const mutations = {
    ADD_FLASH_MESSAGE(state, payload) {
        state.flashMessageItems.unshift(payload);
    },
    REMOVE_FLASH_MESSAGE(state, payload) {
        let index = state.flashMessageItems.findIndex(obj => obj == payload);
        state.flashMessageItems.splice(index,1);
    },
    UPDATE_COFIRM_MESSAGE(state, payload) {
        state.confirmDialog = payload;
    },
    UPDATE_INFO_MESSAGE(state, payload) {
        state.infoDialog = payload;
    },
    UPDATE_IS_LOADING(state, payload) {
        state.isLoading = payload
    },
    UPDATE_SERVER_STATUS(state, payload) {
        state.serverStatus = payload
    },
    UPDATE_MOUSE_POS(state, payload) {
        state.mousePosition = payload
    },
    /*UPDATE_MOVING_OBJECT(state, payload) {
        state.movingObject = payload
    }*/
    UPDATE_API_MONITOR_DATA(state, payload) {
        state.apiMonitorData = payload
    },
    UPDATE_ACTION_DIALOG(state, payload) {
        state.actionDialog = payload
    }
}

const actions = {
    startLoadingProcess() {
        this.dispatch('system/setIsLoading', true)
        this.dispatch('user/getUser').then(() => {
            this.dispatch("vehicle/getAllVehicleStuff", true);
            this.dispatch("vehicle/getVehicleItems").then(() => {
                this.dispatch("vehicle/getTransactions").then(()=> {
                    this.dispatch('system/setIsLoading', false)
                })
            }) 
        })
    },
    responseProcessing({commit}, payload) {
        if(payload.data.flash != undefined) {
            let flash = payload.data.flash;
            if(flash.message != "") {
                commit('ADD_FLASH_MESSAGE', flash);
            }
        } 
    },
    addFlashMessage({commit}, payload) {
        payload.id = uuid.v4();
        commit('ADD_FLASH_MESSAGE', payload);
    },
    removeFlashMessage({commit}, payload) {
        commit('REMOVE_FLASH_MESSAGE', payload);
    },
    startNewConfirm({commit}, dialog) {
        dialog.enabled = true;
        if(dialog.message == undefined) {
            dialog.message = "Bitte bestätigen.";
        }
        if(dialog.confirmText == undefined) {
            dialog.confirmText = "Ja";
        }
        if(dialog.cancelText == undefined) {
            dialog.cancelText = "Abbruch";
        }
        commit('UPDATE_COFIRM_MESSAGE', dialog)
    },
    disableConfirm({commit}, dialog) {
        dialog.enabled = false;
        commit('UPDATE_COFIRM_MESSAGE', dialog)
    },
    startNewInfo({commit}, dialog) {
        dialog.enabled = true;
        if(dialog.message == undefined) {
            dialog.message = "Eine Information";
        }
        if(dialog.confirmText == undefined) {
            dialog.confirmText = "Okay";
        }
/*         if(dialog.cancelText == undefined) {
            dialog.cancelText = "Abbruch";
        } */
        commit('UPDATE_INFO_MESSAGE', dialog)
    },
    disableInfo({commit}, dialog) {
        dialog.enabled = false;
        commit('UPDATE_INFO_MESSAGE', dialog)
    },
    setIsLoading({commit} , payload) {
        commit('UPDATE_IS_LOADING', payload)
    },
    setServerStatus({commit}, server) {
        commit('UPDATE_SERVER_STATUS', server)
    },
    setMousePosition({commit}, position) {
        commit('UPDATE_MOUSE_POS', position)
    },
    /*setMovingObject({commit}, payload) {
        commit('UPDATE_MOVING_OBJECT', payload)
    }*/
    setApiMonitorData({commit}, payload) {
        commit('UPDATE_API_MONITOR_DATA', {})
        commit('UPDATE_API_MONITOR_DATA', payload)
    },
    setActionDialog({commit}, payload) {
        commit('UPDATE_ACTION_DIALOG', payload)
    }
}

const getters = {
    getFlashMessages: state => state.flashMessageItems,
    getConfirmDialog: state => state.confirmDialog,
    getInfoDialog: state => state.infoDialog,
    getActionDialog: state => state.actionDialog,
    getIsLoading: state => state.isLoading,
    getServerStatus: state => state.serverStatus,
    getMousePosition: state => state.mousePosition,
    /*getMovingObject: state => state.movingObject*/
    getApiMonitorData: state => state.apiMonitorData
}


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};