import apiConnection from "@/assets/js/apiConnection"
import Community from "@/assets/model/Community"
Community
const state = {
    communityItems: [],
    focusCommunityId: 0
}


const mutations = {
    UPDATE_COMMUNITY_ITEMS(state, payload) {
        state.communityItems = payload
    },
    REMOVE_COMMUNITY_ITEM(state, id) {
        let index = state.communityItems.findIndex(obj => obj.id == id);
        state.communityItems.splice(index,1);
    },
    CHANGE_FOCUS_COMMUNITY(state, payload) {
        console.log(payload)
        state.focusCommunityId = payload
    }

}

const actions = {
    getAll({commit}) {
        let request = apiConnection.get('/api/communities')
        request.then((response) => {
            let communities = [];
            for(let community of response.data['hydra:member']) {
                let comObject = new Community();
                comObject.openTransportLayer(community);
                communities.push(comObject);
            }
            commit('UPDATE_COMMUNITY_ITEMS', communities)
        })
        return request;
    },

    /**
     * 
     * @param {*} param0 
     * @param {Community} community 
     */
    addNew(commit, community) {
        let request = apiConnection.post('/api/communities', community.getTransportLayer())
        request.then(() => {
            this.dispatch('community/getAll');
        })
        return request;
    },
    remove({commit} , community) {
        commit('REMOVE_COMMUNITY_ITEM', community.id)
        let request = apiConnection.delete('/api/communities/'+community.id);
        return request;
    },
    changeFocus({commit}, payload) {
        if(payload == undefined) {
            commit("CHANGE_FOCUS_COMMUNITY",window.localStorage.getItem('focusCommunity'))
        } else {
            window.localStorage.setItem('focusCommunity',payload)
            commit("CHANGE_FOCUS_COMMUNITY", payload)
        }
    }
}

const getters = {
    getAll: state => state.communityItems,
    getFocusId: state => state.focusCommunityId,
    getFocus (state) {
        return state.communityItems.find(obj => obj.id == state.focusCommunityId)
    }
}


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};