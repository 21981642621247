<template>
    <div v-if="focusCart">
        <DynamicForm  
            :enabled="generalCostsEdit"
            :value="focusCart.generalCosts"
            :formType="'costs'"
            @onCancel="closeModal"
            @onConfirmIncome="saveGeneralCostsIncome"
            @onConfirmOutcome="saveGeneralCostsOutcome" />
        <div class="fixed-top">
            <div class="headerNav m-0">
                <h1 class="ms-4 me-4" @click="editName = true">{{focusCart.name}}</h1>
                <DynamicForm  
                    :enabled="editName"
                    :value="focusCart.name"
                    @onCancel="closeModal"
                    @onConfirm="saveTopic" />
                <div class="actionIcon">
                    <div @click="openTransactionMenu">
                        <i class="fa-solid fa-ellipsis fa-lg"></i>
                    </div>
                </div>
                <div class=" p-2 ps-3 d-flex justify-content-center">
                    <div class="me-3">
                        {{ getProductLength }} Positionen
                    </div>

                    <div :class="getTextStyleByCosts">
                        {{ focusCart.sumCosts }} €
                        <span class="ms-1 " v-if="!isIncome">
                            <i class="fa-solid fa-wallet"></i>
                            <i class="fa-solid fa-arrow-right"></i>
                        </span>
                        <span class="ms-1 " v-if="isIncome">
                            <i class="fa-solid fa-arrow-right"></i>
                            <i class="fa-solid fa-wallet"></i>
                        </span>
                    </div>

                </div>
            </div>
        </div>
    <div class="space">
        
    </div>
    <div class="mb-3 p-1">
        <b>Beschreibung</b>
        <DynamicForm  
            :enabled="descriptionEdit"
            :value="focusCart.description"
            :formType="'textArea'"
            @onCancel="closeModal"
            @onConfirm="saveDescription" />
        <div v-html="focusCart.description">

        </div>
        <div v-if="!focusCart.description" class="text-muted">
            Noch keine Beschreibung
        </div>
    </div>
    <div class="p-1 d-flex justify-content-start">
        <template v-if="!multiSelection">
            <input @keydown.enter="addNewPosition" @keyup="showSaveBtn" v-model="newPositionName" class="w-100 form-control" placeholder="Neue Position" />
            <div id="PLACEHOLDER" class="ms-1" v-if="newPositionButton"></div>
            <SaveButton :enabled="newPositionButton" :saveError="newPositionSaveError" :confirmFunction="addNewPosition" />
        </template>
        <template v-if="multiSelection">
            <div class="w-100 m-auto">
                Auswahl: {{ multiSelectionContainer.length }}
            </div>
            <button @click="askForMultiSelection('income')" v-if="multiSelectionContainer.length" class="minWidth me-1 btn btn-outline-success">
                <i class="fa-solid fa-wallet"></i>
                <i class="fa-solid fa-arrow-right"></i>
            </button>
            <button @click="askForMultiSelection('outcome')" v-if="multiSelectionContainer.length" class="minWidth me-1 btn btn-outline-danger">
                <i class="fa-solid fa-arrow-right"></i>
                <i class="fa-solid fa-wallet"></i>
            </button>
            <button @click="askForMultiSelection('delete')" v-if="multiSelectionContainer.length" class="btn btn-outline-danger">
                <i class="fa-solid fa-trash"></i>
            </button>
            <button @click="cancelSort" v-if="sortWasChanged" class="btn btn-secondary ms-1 me-1">
                <i class="fa-solid fa-x"></i>
            </button>
            <button @click="sortSave" v-if="sortWasChanged" class="btn btn-success">
                <i class="fa-solid fa-floppy-disk"></i>
            </button>
        </template>
    </div>
        <div v-if="!isLoading">
            <template  :key="financePos.id" v-for="(financePos) of cartProductsSorted">
                <FinancePosition :financePosition="financePos" :multiSelectionMode="multiSelection" @onClick="financePosIsClicked" @changeSort="changeSort" :isSelected="isInMultiContainer(financePos)" />
            </template>
        </div>
    </div>
    <div class="text-center mt-2" v-if="isLoading||!focusCart">
        <span><i class="fas fa-spinner fa-spin"></i> Ladevorgang bitte warten ...</span>
    </div>
    <div v-if="openRequest" class="alert alert-success fixed-bottom bottomDistance text-center">
        <i class="fas fa-spinner fa-spin"></i> Hintergrund speichern, bitte nicht verlassen ...  
    </div>
</template>

<script>
import CartList from '@/assets/model/CartList'
import FinancePosition from '@/components/Finance/FinancePosition.vue'
import DynamicForm from '@/components/general/DynamicForm.vue'
import ActionDialog from '@/assets/js/messageObjects/ActionDialogObject'
import { mapGetters } from 'vuex'
import confirmMessage from '@/assets/js/messageObjects/confirmMessageObject'
import SaveButton from '@/components/general/SaveButton.vue'
import CartProduct from '@/assets/model/CartProduct'
import FlashMessage from '@/assets/js/messageObjects/flashMessageObject'

CartList
export default {
    name: "FinanceTransaction",
    data() {
        return {
            isLoading: false,
            editName: false,
            openRequest: 0,
            /**
             * @type {CartList}
             */
            descriptionEdit: false,
            generalCostsEdit: false,
            newPositionName: "",
            newPositionSaveError: false,
            newPositionButton: false,
            sortWasChanged: false,
            multiSelection: false,
            multiSelectionContainer: []
        }
    },
    mounted() {
        this.$store.dispatch('cart/getLists').then(() => {
            //this.$store.dispatch('cart/focusCart')
            //this.cart = this.cartList.find(obj => obj.id == this.$route.params.id)
        })
        this.$store.dispatch('cart/focusCart',this.$route.params.id)
    },
    methods: {
        openTransactionMenu(event) {
            const action = new ActionDialog(event)
            action.addButton("Transaktionsnamen ändern", () => {
                this.editName = true
            })

            action.addButton("Beschreibung ändern", () => {
                this.descriptionEdit = true;
            })

            action.addButton("Allgemeinkosten setzen", () => {
                this.generalCostsEdit = true;
            })

            action.addButton("Mehrfach Auswahl", () => {
                this.multiSelection = !this.multiSelection
            })
            action.setButtonCondition(!this.multiSelection)

            action.addButton("Mehrfach Auswahl beenden", () => {
                this.multiSelection = !this.multiSelection
            })
            action.setButtonCondition(this.multiSelection)

            action.addButton("Löschen", () => {
                let confirm = new confirmMessage(this.$store)
                confirm.
                setMessage("Bist du sicher, dass du die Transaktion löschen möchtest?")
                confirm.setConfirmFunction(() => {
                    this.cart.delete(this.$store)
                    this.$router.push({name: 'financeDashboard'})
                })
                confirm.run();
            },
            'text-danger')

            action.run();
        },
        saveTopic(value) {
            this.focusCart.setName(value);
            this.focusCart.saveDB();
            this.closeModal();
        },
        closeModal() {
            this.editName = false
            this.descriptionEdit = false
            this.generalCostsEdit = false
        },
        saveDescription(value) {
            this.focusCart.setDescription(value);
            this.focusCart.saveDB();
            this.closeModal();
        },
        showSaveBtn() {
            if(this.newPositionName != "") {
                this.newPositionButton = true;
            } else {
                this.newPositionButton = false;
            }
        },
        addNewPosition() {
            this.newPositionSaveError = false
            const flash = new FlashMessage();
            if(this.newPositionName == "") {
                flash.newMessage('danger', "Bitte gebe eine Bezeichnung der Position ein!");
                flash.run();
                this.newPositionSaveError = true
                return
            }
            const product = new CartProduct();
            product.setAmount(1);
            product.setName(this.newPositionName);
            product.setCartList(this.focusCart);
            product.setIsBought(true);
            product.sortIndex = this.getProductLength+1;
            const request = product.saveInDb();
            request.then(() => {
                flash.newMessage('success', 'Die Position wurde erfolgreich im Server gespeichert');
                flash.run()
                this.newPositionName = ""
                this.newPositionButton = false
                this.$store.dispatch('cart/getLists').then(() => {
                    this.sortIndexList();
                }) 
            }).catch(() => {
                this.newPositionSaveError = true
                flash.newMessage('danger', 'Es gab leider einen Fehler beim Abspeichern des Wertes')
                flash.run()
            })
            return request
        },
        financePosIsClicked(position) {
            if(!this.multiSelection) return false;
            if(this.isInMultiContainer(position)) {
                let index = this.multiSelectionContainer.findIndex(obj => obj.id == position.id);
                this.multiSelectionContainer.splice(index, 1);
            } else {
                this.multiSelectionContainer.push(position)
            }
        },
        isInMultiContainer(financePos) {
            return this.multiSelectionContainer.find(obj => obj.id == financePos.id) ? true : false
        },
        askForMultiSelection(actionType) {
            const confirm = new confirmMessage()
            if(actionType == 'delete') {
                confirm.setMessage("Bist du sicher, dass du "+ this.multiSelectionContainer.length + " Produkte löschen möchtest?")
                confirm.setConfirmFunction(() => {
                    this.deleteMultiSelection()
                })
            }

            if(actionType == 'income') {
                confirm.setMessage("Bist du sicher, dass du "+ this.multiSelectionContainer.length + " als Einkommen setzen möchtest?")
                confirm.setConfirmFunction(() => {
                    this.changeAllIncomeDirection(actionType)
                })
            }

            if(actionType == 'outcome') {
                confirm.setMessage("Bist du sicher, dass du "+ this.multiSelectionContainer.length + " als Ausgabe setzen möchtest?")
                confirm.setConfirmFunction(() => {
                    this.changeAllIncomeDirection(actionType)
                })
            }

            confirm.run();
        },
        deleteMultiSelection() {
            for(let pos of this.multiSelectionContainer) {
                /**
                 * @var {CartProduct} product
                 */
                let product = this.focusCart.cartProducts.find(obj => obj.id == pos.id)
                product.delete(this.$store, this.focusCart)
            }
            this.multiSelectionContainer = []
            this.multiSelection = false
        },
        changeAllIncomeDirection(direction = 'income') {
            for(let pos of this.multiSelectionContainer) {
                /**
                 * @var {CartProduct} product
                 */
                let product = this.focusCart.cartProducts.find(obj => obj.id == pos.id)
                if(direction == 'income') {
                    product.setAsIncome(this.$store, this.focusCart)
                } else {
                    product.setAsOutcome(this.$store, this.focusCart)
                }
                product.saveDB()  
            }
            this.multiSelectionContainer = []
            this.multiSelection = false
        },
        saveGeneralCostsIncome(value) {
            if(value < 0) {
                value = value * -1;
            }
            this.focusCart.setGeneralCosts(value);
            this.focusCart.saveDB()
            this.closeModal()
        },
        saveGeneralCostsOutcome(value) {
            if(value > 0) {
                value = value * -1;
            }
            this.focusCart.setGeneralCosts(value);
            this.focusCart.saveDB()
            this.closeModal()
        },
        sortIndexList() {
            this.autoUpdatePause = 3
            let needUpdateProducts = this.cartProductsSorted
            let i = 1;
            for(let updateProduct of needUpdateProducts) {
                updateProduct.sortIndex = i;
                updateProduct.saveDB();
                i++
            }
        },
        multiSort(direction) {
            if(direction == 'down') {
                this.multiSelectionContainer.sort((a,b) => b.sortIndex - a.sortIndex );
            } else {
                this.multiSelectionContainer.sort((a,b) => a.sortIndex - b.sortIndex );
            }
            console.log(this.multiSelectionContainer);
            for(let productSelect of this.multiSelectionContainer) {
                this.singleSort(productSelect, direction)
            }
        },
        singleSort(element, direction) {
            this.sortWasChanged = true;
            let newIndex = element.sortIndex
            if(direction == 'up') {
                newIndex--;
            } else {
                newIndex++;
                let oldProductOnIndex = this.focusCart.cartProducts.find(obj => obj.sortIndex == newIndex)
                oldProductOnIndex.sortIndex--;
                //oldProductOnIndex.saveDB();
            }
            element.sortIndex = newIndex
            //element.saveDB();
            this.focusCart.cartProducts.find(obj => obj.id == element.id).sortIndex = newIndex
            let needUpdateProducts = this.focusCart.cartProducts.filter(obj => (obj.sortIndex >= newIndex && obj.id != element.id))
            let i=1
            for(let updateProduct of needUpdateProducts) {
                updateProduct.sortIndex = newIndex+i;
                //updateProduct.saveDB();
                i++
            }
        },
        changeSort(element, direction) {
            if(this.multiSelectionContainer.length > 0) {
                this.multiSort(direction);
            } else {
                this.singleSort(element)
            }
        },
        sortSave() {
            for(let product of this.focusCart.cartProducts) {
                this.openRequest++;
                product.saveDB().then(() => {
                    this.openRequest--;
                }) ;
                this.sortWasChanged = false;
            }
        },
        cancelSort() {
            this.isLoading = true;
            this.$store.dispatch('cart/getLists').then(() => {
                this.isLoading = false;
                this.sortWasChanged = false;
            })
        }
    },
    computed: {
        ...mapGetters({
            communityId: 'community/getFocusId',
            community: 'community/getFocus',
            cartTypes: 'cart/getCartListTypes',
            cartList: 'cart/getList',
            focusCart: 'cart/getFocusCart'
        }),
        getProductLength() {
            if(this.focusCart.cartProducts) {
                return this.focusCart.cartProducts.length
            }
            return 0
        },
        getPositionSumCosts() {
            let sumPrice = 0;
            if(this.focusCart.cartProducts == undefined) {
                return 0
            }
            for(const pos of this.focusCart.cartProducts) {

                sumPrice += parseFloat(pos.getSumPrice(5))
            }
            return (sumPrice).toFixed(2)
        },
        getTextStyleByCosts() {
            if(this.focusCart.sumCosts >= 0) {
                return ""
            }
            return "text-danger"
        },
        isIncome() {
            if(this.focusCart.sumCosts >= 0) {
                return true
            }
            return false
        },
        cartProductsSorted() {
            if(this.focusCart.cartProducts == undefined) {
                return []
            }
            let products = this.focusCart.cartProducts
            //return products
            products = products.sort((a, b) => {
                const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
                });
            products.sort((a, b) => {
                return a.sortIndex > b.sortIndex
            }) 
            // products.sort((a, b) => a.isBought - b.isBought)
            return products
        }
    },
    components: {
    DynamicForm,
    FinancePosition,
    SaveButton
}
}
</script>

<style lang="scss" scoped>
.actionIcon {
    top: 0.7em;
    right: 0.7em;
    position: absolute;
}

.space {
    margin-top: 110px
}

.minWidth {
    min-width: 60px;
}

.bottomDistance {
    margin-bottom: 75px;
    margin-left: 10px;
    margin-right: 10px;
}
</style>