const helper = {
    objectCopy(object) {
        return JSON.parse(JSON.stringify(object));
    },

    /**
     * 
     * @param {string|object} element Übergebe das element und es wird immer die IRI ausgegeben 
     * @param {params} params falls weder String noch objekt gefunden wird, wird das als Basis genommen z.B. /api/users/
     * @return {string} IRI z.B. /api/users/2
     */
    getIri(element, params = "") {
        if(typeof element === 'string' || element instanceof String) {
            return element;
        } else if(element['@id'] != undefined) {
            return element['@id'];
        } else {
            this.errorMessageByParams(params);
            return params + element.id
        }
    },

    errorMessageByParams(params) {
        if(params == "") {
            console.error("ACHTUNG es fehlen die Parameter")
        }
    },

    /**
     * 
     * @param {array} elementArray 
     * @param {params} falls weder String noch objekt gefunden wird, wird das als Basis genommen 
     * @returns 
     */
    buildIriArray(elementArray, params) {
        const newIriArray = []
        for(let element of elementArray) {
            newIriArray.push(this.getIri(element, params));
        }
        return newIriArray;
    }
}

export default helper;