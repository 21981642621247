<template>
    <div class="alert alert-danger">
        {{errorMessage}}
        <div v-if="error" class="float-end pointer" @click="toggleDetails">
            Details <i v-if="!isDetailOpen" class="fa-solid fa-caret-down"></i><i v-if="isDetailOpen" class="fa-solid fa-caret-up"></i>
        </div>
        <div v-if="isDetailOpen">
            <hr>
            <b>{{error.message}}</b>
            <div>
                {{error.config.url}}
            </div>
            <div> 
                {{error.response.data['hydra:description']}}
            </div>
            <div> 
                {{error.response.data.detail}}
            </div>
            <div class="mt-3" v-if="!error.response.data['hydra:description']"> 
                {{error.response.data}}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        
    },
    data() {
        return {
            isDetailOpen: false
        }
    },
    methods: {
        toggleDetails() {
            this.isDetailOpen = !this.isDetailOpen;
        }
    },
    props: {
        error: {
            type: Object,
            default: null
        },
        errorMessage: {
            type: String,
            default: "Es ist ein Fehler aufgetreten"
        }
    }
}
</script>