import axios from "axios"
//import subscriptionItems from '../../assets/subscription/items.json';
import apiConnection from "@/assets/js/apiConnection.js"


axios.defaults.withCredentials= true;
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://localhost:8000';

const state = {
    user: null,
    userSelect: {},
    userList: [],
    isUserLoggedIn: false
}


const mutations = {
    /**
     * 
     * @param {*} state 
     * @param {array} payload Gebe die User als Array an 
     * @param {boolean} clearBefore Setze den Wert auf True, damit der vorherige Speicher gelöscht wird 
     */
    UPDATE_USERS (state, payload) {
        if(payload.clearBefore === true) {
            state.userList = [];
        } 
        for(let userItem of payload.data) {
            let index = state.userList.findIndex(obj => obj.id == userItem.id)
            if(index >= 0 ) {
                state.userList[index] = userItem;
            } else {
                state.userList.push(userItem);
            }
        }
    },
    UPDATE_USER ( state, payload) {
        state.user = payload
        state.user['@id'] = '/api/users/'+payload.id
        if(state.user == undefined || state.user == null) {
            state.isUserLoggedIn = false;
        } else {
            state.isUserLoggedIn = true;
        }
    },
    UPDATE_USER_SELECT ( state, payload) {
        state.userSelect = payload
    }
}

const actions = {
    login(commit, payload) {
        const request =  apiConnection.postForm('/login',payload);
        request.then(() => {
            this.dispatch('user/getUser');
        })
        return request;
    },
    logout({commit}) {
        const request = apiConnection.get('/logout');
        commit('UPDATE_USER', null);
        return request;    
    },
    async getUser({commit}, vueInstance) {
        const request = apiConnection.get('/getMe');
        request.then((response) => {
            if(vueInstance != undefined) {
                if(response.data == null) {
                    vueInstance.$router.push({name: 'login'} )
                }
            }
            commit('UPDATE_USER', response.data.user)
            this.dispatch('system/setServerStatus', response.data.system)
        })
        return request;
    },
    /**
     * 
     * @param {*} param0 
     * @param {integer} payload ID des Nutzers 
     * @returns 
     */
    async getUserSelect({commit}, payload) {
        const request = apiConnection.get('/api/users/'+payload);
        request.then((response) => {
            commit('UPDATE_USER_SELECT', response.data)
        })
        return request;
    },
    async saveUser({commit}, payload) {
        let request = apiConnection.patch('/api/users/'+payload.id, payload)
        request.then((response) => {
            commit('UPDATE_USERS', {data: [response.data]})
        })
        return request;    
    },
    /**
     * 
     * @param {*} param0 
     * @param {boolean} clearBefore Setze den Wert auf True, damit der vorherige Speicher gelöscht wird 
     * @returns 
     */
    async getUserList({commit}, clearBefore = false) {
        let request = apiConnection.get('/api/users');
        request.then((response) => {
            commit('UPDATE_USERS', {data: response.data['hydra:member'],
                                    clearBefore: clearBefore
                                    });
        })
        return request;
    },
    async addNewUser({commit}, payload) {
        let request = apiConnection.post('/api/users', payload);
        request.then((response) => {
            commit('UPDATE_USERS',  {data: [response.data]})
        })
        return request;
    },
    /**
     * 
     * @param {*} param0 
     * @param {*} userId Bitte nur die User Id angeben 
     * @returns 
     */
    async removeUser(commit, userId) {
        let request = apiConnection.delete('/api/users/'+userId);
        request.then(() => {
            this.dispatch('user/getUserList', true)
        })
        return request;
    }
}

const getters = {
    getUserList: state => state.userList,
    getUser: state => state.user,
    getUserSelect: state => state.userSelect,
    isUserLoggedIn: state => state.isUserLoggedIn,
    isAdmin (state) {
        if(state.user == undefined) {
            return false
        }
        if(state.user.roles == undefined) {
            return false
        }
        if(state.user.roles.find(obj => obj == 'ROLE_ADMIN') !== undefined) {
            return true
        } else {
            return false
        }
    }
}


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};