<template>
    <div class="bg-dark p-2">
        Requests: {{ apiData.counter }}
       
    </div>
    <div v-if="false">
        <div :key="index" v-for="(request, index) of apiData.requests">
            <div class="d-flex justify-content-between">
                <div>{{ request.method }}</div>
                <div>{{ request.time }}</div>
                <div>{{ request.url }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import apiConnection from '@/assets/js/apiConnection';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {}
    },
    created() {
        this.$store.dispatch('system/setApiMonitorData', {})
        apiConnection.setState(this.$store)

    },
    computed: {
        ...mapGetters({
            apiData: 'system/getApiMonitorData'
        })
    }
}
</script>

<style scoped>
</style>