//import subscriptionItems from '../../assets/subscription/items.json';
import moment from "moment"
import apiConnection from "@/assets/js/apiConnection"
import helper from "@/assets/js/helper"

const state = {
    vehicleItems: [],
    transactionCategoryItems: [],
    fuelTypes: [],
    transactions: [],
    focusCarId: 0,
    analyse: {}
}

/*const singleYearRequest = async (startYear,endYear) => {
    let url = 'https://localhost:8000/api/v1/vehicle/getTransactions/'+startYear+'/'+endYear;
    return axios.get(url).then((response) => {
       return response.data.content
    })
}*/

function getAllTransactionHelper(commit, page = 1) {
    new Promise((resolve) => {
        let request = apiConnection.get('/api/vehicle_transactions?page='+page);
        request.then((response) => {
            commit('ADD_TRANSACTIONS', response.data['hydra:member'])
            if(response.data['hydra:member'].length > 0) {
                getAllTransactionHelper(commit, page+1)
            } else {
                resolve();
            }
        })
    })

}


const mutations = {
    UPDATE_VEHICLES (state, payload) {
        if(payload.clearBefore === true) {
            state.vehicleItems = [];
        } 
        for(let vehicle of payload.data) {
            let index = state.vehicleItems.findIndex(obj => obj.id == vehicle.id);
            vehicle.buyDate = moment(vehicle.buyDate).format('YYYY-MM-DD')
            vehicle.constructionYear = moment(vehicle.constructionYear).format('YYYY-MM-DD')
            vehicle.created =  moment(vehicle.created).format('YYYY-MM-DD HH:mm')
            if(index >= 0) {
                state.vehicleItems[index] = vehicle;
            } else {
                state.vehicleItems.push(vehicle);
            }
        }
    },
    REMOVE_VEHICLE(state, vehicle) {
        let index = state.vehicleItems.findIndex(obj => obj.id == vehicle.id)
        state.vehicleItems.splice(index, 1);
    },
    CLEAR_TRANSACTIONS(state) {
        state.transactions = []
    },
    ADD_TRANSACTIONS(state, payload) {
        console.log(payload)
        payload.map((transaction) => {
            transaction.created =  moment(transaction.created).format('YYYY-MM-DD HH:mm')
            if(transaction.updated != null) {
                transaction.updated =  moment(transaction.updated).format('YYYY-MM-DD HH:mm')
            }
        })
        for(let trans of payload) {
            let index = state.transactions.findIndex(obj => obj.id === trans.id)
            if(index >= 0) {
                state.transactions[index] = trans;
            } else {
                state.transactions.push(trans)
            }

        } 
    },
    REMOVE_TRANSACTION(state, payload) {
        let index = state.transactions.findIndex(obj => obj.id === payload.id)
        state.transactions.splice(index,1)
    },
    UPDATE_TRANSACTION_CATEGORY (state, payload) {
        state.transactionCategoryItems = payload.data
    },
    UPDATE_FUEL_TYPES (state, payload) {
        state.fuelTypes = payload.data
    },
    CHANGE_FOCUS_CAR(state, payload) {
        state.focusCarId = payload
    },
    UPDATE_ANALYSE(state, payload) {
        state.analyse = payload
    }
}

const actions = {
    getVehicleItems({commit}, clearBefore = false) {
        let request = apiConnection.get('/api/vehicles');
        request.then((response) => {
            commit('UPDATE_VEHICLES', {data: response.data['hydra:member'],
                                        clearBefore: clearBefore});
        })
        return request;
    },
    async getTransactions({commit}) {
        let promise = getAllTransactionHelper(commit)


        return promise;

    },
    changeFocusCar({commit}, payload) {

        if(payload == undefined) {
            commit("CHANGE_FOCUS_CAR",window.localStorage.getItem('focusVehicle'))
        } else {
            if(state.focusCarId != payload) {
                let info = {
                    confirmText: "Alles klar!",
                    message: "Du bist in das Fahrzeug eingestiegen. Alle Änderungen werden nun auf <b>\"" + state.vehicleItems.find(obj => obj.id == payload).name + "\"</b> angewendet. Du kannst das Fahrzeug in der Garage wechseln"
                }
                this.dispatch('system/startNewInfo', info)
            }
            window.localStorage.setItem('focusVehicle', payload)
            commit("CHANGE_FOCUS_CAR",payload)
        }
    },
    getAllFuelTypes({commit}, clearBefore = false) {
        let request = apiConnection.get('/api/fuel_types');
        request.then((response) => {
            commit('UPDATE_FUEL_TYPES', {data: response.data['hydra:member'],
                                            clearBefore: clearBefore})
        })
        return request;
    },
    getAllTransactionCategories({commit}, clearBefore = false) {
        let request = apiConnection.get('/api/vehicle_transaction_categories');
        request.then((response) => {
            console.log(response)
            commit('UPDATE_TRANSACTION_CATEGORY', {data: response.data['hydra:member'],
                clearBefore: clearBefore})
        })
        return request;
    },
    addVehicle({commit}, payload) {
        let request = apiConnection.post('/api/vehicles', payload);
        request.then((response) => {
            commit('UPDATE_VEHICLES', {data: [response.data]})
        })
        return request;
    },
    removeVehicle({commit}, payload) {
        let request = apiConnection.delete('/api/vehicles/'+payload.id)
        commit('REMOVE_VEHICLE', payload)
        request.then(() => {
            this.dispatch('vehicle/getVehicleItems', true)
        })
        return request;
    },
    saveVehicle({commit}, payload) {
        const prepareVehicle = helper.objectCopy(payload);
        prepareVehicle['@id'] = '/api/vehicles/'+payload.id
        prepareVehicle.owner = payload.owner['@id']
        if(prepareVehicle.mainPicture != undefined && prepareVehicle.mainPicture['@id'] != undefined) {
            prepareVehicle.mainPicture = prepareVehicle.mainPicture['@id'];
        }
        prepareVehicle.favoriteFuelType = helper.getIri(prepareVehicle.favoriteFuelType, '/api/fuel_types/')

        let IRI = [];
        for(let type of prepareVehicle.FuelTypes) {
            if(typeof type === 'object') {
                IRI.push(type['@id']);
            } else {
                IRI.push(type);
            }
        }
        prepareVehicle.FuelTypes = IRI;
        console.log(prepareVehicle)
        let request = apiConnection.patch('/api/vehicles/'+prepareVehicle.id, prepareVehicle)
        request.then((response) => {
            commit('UPDATE_VEHICLES', {data: [response.data]})
        })
        return request;
    },
    addTransaction({commit}, transaction) {
        const sendTransaction = helper.objectCopy(transaction);
        if(sendTransaction.vehicle.id == undefined) {
            sendTransaction.vehicle = '/api/vehicles/'+sendTransaction.vehicle;
        } else {
            sendTransaction.vehicle = '/api/vehicles/'+sendTransaction.vehicle.id; 
        }
        if(sendTransaction.FuelType == undefined) {
            sendTransaction.FuelType = null;
        } else {
            sendTransaction.FuelType = sendTransaction.FuelType['@id'];
        }
        sendTransaction.amount = sendTransaction.amount.toString();
        sendTransaction.costs = sendTransaction.costs.toString();
        if(typeof sendTransaction.category === 'object') {
            sendTransaction.category = '/api/vehicle_transaction_categories/'+sendTransaction.category.id;
        } else {
            sendTransaction.category = '/api/vehicle_transaction_categories/'+sendTransaction.category;
        }

        let request = apiConnection.post('/api/vehicle_transactions', sendTransaction);
        request.then((response)=> {
            commit('ADD_TRANSACTIONS', [response.data]);
        })
        return request;
    },
    removeTransaction({commit}, transaction) {
        commit('REMOVE_TRANSACTION', transaction)
        let request = apiConnection.delete('/api/vehicle_transactions/' + transaction.id);
        return request;
    },
    updateTransaction({commit}, transaction) {
        const sendTransaction = helper.objectCopy(transaction);
        if(sendTransaction.vehicle != undefined) {
            sendTransaction.vehicle = '/api/vehicles/'+sendTransaction.vehicle.id;
        }
       
        if(sendTransaction.FuelType == undefined) {
            sendTransaction.FuelType = null;
        } else {
            sendTransaction.FuelType = sendTransaction.FuelType['@id'];
        }
        sendTransaction.amount = sendTransaction.amount.toString();
        sendTransaction.costs = sendTransaction.costs.toString();
        sendTransaction.category = '/api/vehicle_transaction_categories/'+sendTransaction.category.id;
        let request = apiConnection.patch('/api/vehicle_transactions/'+transaction.id, sendTransaction);
        request.then((response)=> {
            commit('ADD_TRANSACTIONS', [response.data]);
        })
        return request;
    },
    uploadVehicleImage(commit, payload) {
        let request = apiConnection.uploadFiles(payload.files,'Vehicle', payload.vehicle.id)
        return request; 
    },
    uploadTransactionImage(commit, payload) {
        let request = apiConnection.uploadFiles(payload.files,'VehicleTransaction', payload.transaction.id)
        return request; 
    },
    setMainImage(commit, payload) {
        let sendData = {
            '@id': '/api/vehicles/'+payload.vehicle.id
        }
        if(payload.image == null) {
            sendData.mainPicture = null
        } else {
            sendData.mainPicture = '/api/file_holders/'+payload.image.id
        }
        let request = apiConnection.patch('/api/vehicles/'+payload.vehicle.id, sendData)
        return request
    },
    getAnalyse({commit}, vehicle= undefined) {
        let vehicleId = 0;
        if(vehicle == undefined) {
            vehicleId = state.focusCarId;
        } else {
            vehicleId = vehicle.id
        }
        let request = apiConnection.get('/apiController/vehicle/getAnalyses/' + vehicleId)
        request.then((response) => {
            commit('UPDATE_ANALYSE', response.data)
        })
        return request
    }


}

const getters = {
    getVehicleItems: state => state.vehicleItems,
    getTransactionCategories: state => state.transactionCategoryItems,
    getTransactions: state => state.transactions,
    getFuelTypes: state => state.fuelTypes,
    getFocusCar: state => state.focusCarId,
    getAnalyse: state => state.analyse
}


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};