<template>
    <h1>Transaktion</h1>
    <div class="d-flex justify-content-between mb-2">
<!--         <button @click="goToVehicleList" class="txBtn txBtn-primary">Zur Fahrzeugliste</button> -->
        <div class="d-flex justify-content-start">
            <div class="ms-2 txBtn txBtn-primary"  data-bs-toggle="collapse" data-bs-target="#collapseFilter" aria-expanded="false" aria-controls="collapseFilter"><i class="fa-solid fa-filter fa-lg"></i></div>
            <div @click="clearFilter" v-if="isFiltering" class="ms-2 txBtn txBtn-primary"><i class="fa-solid fa-ban fa-lg"></i></div>
        </div>
        <button @click="addNewTransaction" class="txBtn txBtn-primary">Neue Transaktion</button>
    </div>
    <div class="filterList collapse p-2" id="collapseFilter">
        <div class="d-flex justify-content-between">
            <input v-model="searchText" class="form-control me-4" placeholder="Suche mittels Schalgwörter ..." />
            <div v-if="getTransactions.length == 0" class="minWidth text-center m-auto">keine Einträge gefunden</div>
            <div v-if="getTransactions.length == 1" class="minWidth text-center m-auto">1 Eintrag gefunden </div>
            <div v-if="getTransactions.length > 1" class="minWidth text-center m-auto">{{getTransactions.length}} Einträge gefunden</div>
        </div>
        <div class="card bg-black mt-1" :key="cat.id" v-for="(cat) of transCategories">
            <button class=" btn " :class="getFilterCatClass(cat)" @click="filterByCategory(cat)"  data-bs-toggle="collapse" data-bs-target="#collapseFilter">
                <i :class="cat.faIcon" class="float-start mt-1"></i>  {{cat.name}}
            </button>
        </div>
    </div>
    <vehicle-transaction-card :vehicle="selectedVehicle" :key="trans.id" :transaction="trans" v-for="trans in getTransactions" />

</template>

<script>
import { mapGetters } from 'vuex';
import VehicleTransactionCard from '../../components/vehicle/VehicleTransactionCard.vue'

export default {
    name: 'VehicleTransaction',
    emits: ["updatedcount"],
    data() {
        return {
            filterCategory: null,
            searchText: ""
        }
    },
    beforeCreate() {
        this.$store.dispatch('vehicle/getVehicleItems')
        this.$store.dispatch('vehicle/getTransactions')
        this.$store.dispatch('vehicle/getAllTransactionCategories')
        this.$store.dispatch('vehicle/getAllFuelTypes')
    },
    created() {
        this.init();
    },
    computed: {
        ...mapGetters({
            vehicleItems: 'vehicle/getVehicleItems',
            transactions: 'vehicle/getTransactions',            
            transCategories: 'vehicle/getTransactionCategories'
        }),
        getTransactions() {
            let vehicleTransactions = this.transactions.filter(obj => obj.vehicle.id ==  this.$route.params.vehicleId)
            if(this.filterCategory != null) {
                vehicleTransactions = vehicleTransactions.filter(obj => obj.category.id == this.filterCategory.id)
            }
            if(this.searchText != "") {
                vehicleTransactions = vehicleTransactions.filter((obj) => {
                    if(obj.name.toLowerCase().includes(this.searchText.toLowerCase())) {
                        return true
                    }
                    if(obj.description != null) {
                        if(obj.description.toLowerCase().includes(this.searchText.toLowerCase())) {
                            return true
                        }
                    }
                    return false
                })
            }
            vehicleTransactions.sort(this.compare);
            return vehicleTransactions
        },
        selectedVehicle() {
            let vehicle = {
                transactions: []
            }
            let loadVehicle = this.vehicleItems.find(obj => obj.id == this.$route.params.vehicleId);
            if(loadVehicle) {
                return loadVehicle
            }
            return vehicle
        },
        isFiltering() {
            if(this.filterCategory != null || this.searchText != "") {
                return true;
            } else {
                return false
            }
        }
    },
    methods: {
        init() {
        },
        addNewTransaction() {
            this.$router.push({name: 'transactionForm'})
            /*let trans = {
                vehicle: this.$route.params.vehicleId
            }
            this.$store.dispatch('vehicle/addTransaction', trans).then(() => {
                this.$store.dispatch('vehicle/getTransactions')
            })*/
        },
        goToVehicleList() {
            this.$router.push({name: 'vehicleList'});
        },
        compare(a, b) {
            if ( a.created > b.created ){
                return -1;
            }
            if ( a.created < b.created ){
                return 1;
            }
            return 0;
        },
        filterByCategory(category) {
            if(category == this.filterCategory) {
                this.filterCategory = null
                return
            }
            this.filterCategory = category
        },
        getFilterCatClass(category) {
            if(category == this.filterCategory) {
                return "btn-primary"
            }
            return "btn-outline-primary"
        },
        clearFilter() {
            this.searchText = "";
            this.filterCategory = null
        }
    },
    components: {
        VehicleTransactionCard
    }
}
</script>

<style scoped lang="scss">
    .minWidth {
        min-width: 150px;
    }
</style>