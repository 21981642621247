<template>
    <div  @click="setDisable" class="dgModal">
        <div @click.stop class="card dgModal-content text-dark">
            <div class="card-header text-center">
                <h3>Neues Gemeinschaft</h3>
            </div>
            <div class="card-body text-black">
                <communication-error v-if="communicationError" :error="communicationError" />
                <div class="mb-2">
                        <label for="email" class="form-label">Gemeinschaftsname</label>
                        <input  @keydown.enter="addNew" v-model="communityEdit.name" class="form-control" type="text" placeholder="Name deiner Gemeinschaft" />
                        <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                </div>
                <button @click="addNew" for="save" class="form-control mt-5 btn btn-success">Hinzufügen</button>
            </div>
        </div>
    </div>
</template>

<script>
import CommunicationError from '../general/CommunicationError.vue';
import Community from '@/assets/model/Community';
// import flashMessage from '@/assets/js/messageObjects/flashMessageObject';
import { mapGetters } from 'vuex';

export default {
data() {
    return {
        communityEdit: {},
        communicationError: null
    }
},
created() {

},
methods: {
    setDisable() {
        this.$emit('setDisable');
    },
    addNew() {
            let community = new Community();
            community.name(this.communityEdit.name);
            community.addChief('/api/users/'+this.user.id)
            community.addMember('/api/users/'+this.user.id)
            this.$store.dispatch('community/addNew', community);
            this.setDisable();
        }
    },
    computed: {
        ...mapGetters({
            user: 'user/getUser'
        })
    },
    components: {
        CommunicationError
    }
}
</script>