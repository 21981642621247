<template>
<fuel-station-list :vehicle="vehicle" v-if="showFuelImportWindow" :wasImported="wasImported" @importFuelStation="importStation" @close="closeImportStation" />
<h1>Tanken <span  v-if="vehicle">| {{vehicle.name}}</span></h1>


<!-- <button @click="goToVehicleList" class="d-none d-md-inline txBtn txBtn-primary">Zur Fahrzeugliste</button> -->
<div class="col-12 col-sm-6  col-md-4 col-lg-3">
    <div class="card mt-1 bg-dark d-flex align-items-center">
        <div class="card-image">
            <img class="img-fluid rounded" src="@/assets/files/vehicle/gas-pump-small.jpg" />
        </div>
    </div>
</div>
<div class="mt-2">
    <div v-if="isAllowedToSave" >
        <button v-if="!isSaving" @click="addAndSaveTransaction" class="w-100 btn btn-success mb-1">Speichern</button>
    </div>
    <button  @click="showFuelImportWindow=true" class="w-100 btn btn-info"><i class="fa-solid fa-compass fa-lg"></i> Tankstelle lokalisieren</button>
</div>
<div class="mt-2 row">
    <div class="col-6 ">
        <div class="card bg-dark">
            <div  @click="openMileageInput" class="card-header pointer text-center bg-dark">
                <h3>KM-Stand</h3>
            </div>
            <div  @click="openMileageInput" v-if="!isOpenMileageInput" class="card-text pointer text-center" style="font-size:25px">
                {{mileage}} km
            </div>
            <div  v-if="isOpenMileageInput" class="card-text text-center" >
                <input type="number" @keyup="convertInputToNumber" v-on:blur="closeMileageInput" @keydown.enter="openCostsInput" ref="mileage" class="form-control" v-model="mileage" />
            </div>
        </div>
    </div>
    <div class="col-6" v-if="vehicle">
        <div class="card bg-dark">
            <div  @click="openFuelTypeInput" class="card-header pointer text-center bg-dark">
                <h3>Kraftstoff</h3>
            </div>
            <div @click="openFuelTypeInput" v-if="!isOpenFuelTypeInput" class="card-body bg-dark text-center p-0" style="font-size:25px">
                {{FuelType.name}}
            </div>
            <select @change="closeFuelTypeInput" v-if="isOpenFuelTypeInput" class="form-select" v-model="FuelType"> 
                <option :key="type.id" :value="type" v-for="(type) of vehicle.FuelTypes">
                    {{type.name}}
                </option>
            </select>
        </div>
    </div>
</div>
<div class="mt-2 row">
    <div class="col-6 ">
        <div class="card bg-dark">
            <div class="card-header pointer text-center  bg-dark">
                <h3  @click="openCostsInput">Preis</h3>
                <div @click="showFuelImportWindow=true" class="compass"><i class="fa-solid fa-compass fa-lg"></i></div>
            </div>
            <div v-if="!isOpenCostsInput" @click="openCostsInput" class="card-text pointer text-center" style="font-size:25px">
                {{priceBasis}},{{getDec(priceCent)}} <span class="text-muted" style="font-size:20px">{{priceMilli}}</span> €
            </div>
            <div  v-if="isOpenCostsInput" class="card-text text-center" >
                <div class="input-group mb-3">
                    <div @click="focusEuro" class="input-group">
                        <span class="input-group-text bg-primary text-dark w-50">€</span>
                        <input  @click="focusEuro" ref="priceBasis" @keyup="convertInputToNumber" type="number" @keydown.enter="focusCent" @keydown="checkDecimalCostKeyOne" class="form-control" v-model="priceBasis" />
                    </div>
                </div>
                <div  @click="focusCent" class="input-group mb-3">
                    <div class="input-group">
                        <span class="input-group-text bg-primary text-dark w-50">Cent</span>
                        <input  ref="priceCent" type="number" @keyup="convertInputToNumber" @keydown.enter="focusMilli"  @keydown="checkDecimalCostKeyTwo" class="form-control" v-model="priceCent" />
                    </div>
                </div>
                <div class="input-group mb-3">
                    <div @click="focusMilli" class="input-group">
                        <span class="input-group-text bg-primary text-dark w-50">Milli Cent</span>
                        <input   ref="priceMilli" type="number" @keyup="convertInputToNumber" @keydown.enter="openAmountInput" class="form-control" v-model="priceMilli" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-6 ">
        <div class="card bg-dark inputCol m-auto">
            <div @click="openAmountInput" class="card-header pointer text-center bg-dark">
                <h3>Menge</h3>
            </div>
            <div v-if="!isOpenAmountInput" @click="openAmountInput" class="card-text pointer text-center" style="font-size:25px">
                {{amountBasis}},{{getDec(amountDecimal)}} l
            </div>
            <div  v-if="isOpenAmountInput" class="card-text text-center" >
                <div class="input-group mb-3">
                    <div  @click="focusAmountBasis"  @keydown="checkDecimalAmountKey" class="input-group">
                        <span class="input-group-text bg-primary text-dark w-50">Liter</span>
                        <input  ref="amountBasis" @keyup="convertInputToNumber"  @keydown.enter="focusAmountDecimal" type="number" class="form-control"  v-model="amountBasis" />
                    </div>
                </div>
                <div class="input-group mb-3">
                    <div  @click="focusAmountDecimal" class="input-group">
                        <span class="input-group-text bg-primary text-dark w-50">Zentiliter</span>
                        <input  ref="amountDecimal" @keyup="convertInputToNumber"  @keydown.enter="closeAll" type="number" class="form-control" v-model="amountDecimal" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div @click="closeAll" class="mt-2 row">
    <div class="col-12 ">
        <div class="card bg-dark">
            <div class="card-header text-center bg-dark">
                <h3>Gesamt</h3>
            </div>
            <div class="card-text text-center" style="font-size:30px">
                {{getSumPrice}} €
            </div>
        </div>
    </div>
</div>
<div @click="closeAll" class="mt-2 row" v-if="selectedStation.place">
    <div class="col-12 ">
        <div class="card bg-dark text-center">
            <b>{{selectedStation.name}}<br></b>
            {{selectedStation.postCode}} {{selectedStation.place}}<br>
            {{selectedStation.street}} {{selectedStation.houseNumber}}
        </div>
    </div>
</div>
<!-- <button class="w-100 mt-3 btn btn-light">Abbrechen</button> -->



</template>


<script>
import { mapGetters } from 'vuex';
import FuelStationList from '../../components/vehicle/FuelStationList.vue'
import moment from 'moment';

export default {
    name: "VehicleDashboard",
    data() {
        return {
            focusCarId: null,
            isOpenMileageInput: false,
            isOpenCostsInput: false,
            isOpenAmountInput: false,
            mileage: 0,
            priceBasis: 1,
            priceCent: 30,
            priceMilli: 9,
            amountBasis:45,
            amountDecimal: 0,
            isSaving: false,
            selectedStation: {},
            showFuelImportWindow: false,
            FuelType: {},
            isOpenFuelTypeInput: false,
            wasImported: false
        }
    },
    beforeCreate() {
        this.$store.dispatch('vehicle/getVehicleItems');
        this.$store.dispatch('vehicle/getAllFuelTypes');
        this.$store.dispatch('vehicle/changeFocusCar');
    },
    created() {
        this.focusCarId = window.localStorage.getItem('focusVehicle')
        if(this.focusCarId == null) {
            this.goToVehicleList();
        }
        this.vehicle.favoriteFuelType
        this.FuelType = this.FuelTypes.find(obj => obj.name.toLowerCase() == this.vehicle.favoriteFuelType.name.toLowerCase())
        if(this.autoImport) {
            this.showFuelImportWindow = true
        }

       // this.getLocation();
    },
    updated() {

    },
    computed: {
        ...mapGetters({
            vehicleItems: 'vehicle/getVehicleItems',
            stations: 'fuelprice/getStations',
            FuelTypes: 'vehicle/getFuelTypes',
            autoImport: 'localSetting/getAutoFuelPriceImport'
        }),
        vehicle() {
            let vehicle = this.vehicleItems.find(obj => obj.id == this.focusCarId);
            if(vehicle != undefined) {
                //
            } else {
                return null
            }
            if(vehicle.FuelTypes.length == 0 || vehicle.favoriteFuelType == null) {
                let info = {
                    message: "Es fehlen Angaben zum Fahrzeug um dieses Formular nutzen zu können.<br><br>Bitte füge Kraftstoffarten und deine favorisierte Kraftstoffart hinzu.<br><br>Du wirst nun zum Formular weitergeleitet, um dies nachzuholen.",
                    confirmText: "Okay",
                    confirmFunction: () => {
                        this.$router.push({name: 'vehicleEdit', params: {vehicleId: vehicle.id}})
                    }
                }
                this.$store.dispatch('system/startNewInfo', info)
            }
            return vehicle;
        },
        getSumPrice() {
            return (this.getCosts * this.getAmount).toFixed(2)
        },
        getCosts() {
            let costsString = this.priceBasis + '.' + this.getDec(this.priceCent) + this.priceMilli
            return parseFloat(costsString)
        },
        getAmount() {
            let amountString = this.amountBasis + '.' + this.getDec(this.amountDecimal)
            return parseFloat(amountString)
        },
        isAllowedToSave() {
            if(!this.vehicle) {
                return false
            }
            if(this.mileage < 1 ) {
                return false
            }
            if(this.FuelType.name == undefined) {
                return false
            }
            return true
        }
    },
    methods: {
        openMileageInput() {
            this.isOpenCostsInput = false;
            this.isOpenAmountInput = false
            this.isOpenMileageInput = true;
            this.$nextTick(() => {
                this.$refs.mileage.select();
            })

        },
        convertInputToNumber(event) {
            event.target.value = parseInt(event.target.value)
        },
        checkDecimalAmountKey(event) {
            if(event.key == "," ||event.key == ".") {
                this.focusAmountDecimal();
            }
        },
        checkDecimalCostKeyOne(event) {
            if(event.key == "," ||event.key == ".") {
                this.focusCent();
            }
        },
        checkDecimalCostKeyTwo(event) {
            if(event.key == "," ||event.key == ".") {
                this.focusMilli();
            }
        },
        openCostsInput() {
            this.isOpenCostsInput = true;
            this.isOpenAmountInput = false
            this.isOpenMileageInput = false;
            this.$nextTick(() => {
                this.focusEuro();
            })
        },
        closeCostsInput() {
            this.isOpenCostsInput = false;
        },
        closeMileageInput() {
            this.isOpenMileageInput = false;
        },
        openAmountInput() {
            this.isOpenCostsInput = false;
            this.isOpenAmountInput = true;
            this.isOpenMileageInput = false;
            this.$nextTick(() => {
                this.$refs.amountBasis.select();
            })
        },
        closeAll() {
            this.isOpenCostsInput = false;
            this.isOpenAmountInput = false;
            this.isOpenMileageInput = false;
        },
        focusEuro() {
            this.$refs.priceBasis.select();
        },
        focusCent() {
            this.$refs.priceCent.select();
        },
        focusMilli() {
            this.$refs.priceMilli.select();
        },
        focusAmountBasis() {
            this.$refs.amountBasis.select();
        },
        focusAmountDecimal() {
            this.$refs.amountDecimal.select();
        },
        goToTransaction() {
            this.$router.push({name: 'vehicleTransaction' , params: { vehicleId: this.vehicle.id}})
        },
        goToVehicleList() {
            this.$router.push({name: 'vehicleList'})
        },
        getDec(value) {
            if(value < 10) {
                return '0'+value
            }
            return value
        },
        addAndSaveTransaction() {
            this.$store.dispatch('system/setIsLoading', true)
            this.isSaving = true
            let trans = {
                mileage: this.mileage,
                vehicle: this.vehicle.id,
                amount: this.getAmount,
                costs: this.getCosts,
                category: 1,
                FuelType: this.FuelType
          
            }
            if(this.selectedStation.name != undefined) {
                trans.name = this.selectedStation.name;
                trans.description = this.selectedStation.postCode + " " + this.selectedStation.place + " " +  this.selectedStation.street + " " + this.selectedStation.houseNumber
            }

            this.$store.dispatch('vehicle/addTransaction', trans).then(() => {
                this.$store.dispatch('vehicle/getTransactions', moment().format('YYYY')).then(() => {
                        this.$store.dispatch('system/setIsLoading', false)
                        this.$router.push({name: 'vehicleDashboard'})
                })
            }) 
        },
        openFuelTypeInput() {
            this.isOpenFuelTypeInput = true;
        },
        closeFuelTypeInput() {
            this.isOpenFuelTypeInput = false;
        },
        importStation(station, fuelTypeName) {
            if(station == undefined) {
                return
            }
            this.wasImported = true
            this.selectedStation = station;
            this.FuelType = this.FuelTypes.find(obj => obj.name.toLowerCase() == fuelTypeName.toLowerCase())
            this.priceBasis = parseFloat(station[fuelTypeName.toLowerCase()].toString().substring(0,1));
            this.priceCent = parseFloat(station[fuelTypeName.toLowerCase()].toString().substring(2,4));
            this.priceMilli = parseFloat(station[fuelTypeName.toLowerCase()].toString().substring(4,5));
            this.showFuelImportWindow = false;
        },
        closeImportStation() {
            this.showFuelImportWindow = false;
        }
          
    },
    components: { 
        FuelStationList 
        },
}
</script>

<style lang="scss" scoped>
    .compass {
        position: absolute;
        top: 12px;
        right: 12px;
    }
</style>