<template>
    <div v-if="enabled" class="backgroundForm fixed-top pt-5 p-2">
        <div class="card">
            <div class="card-header">
                {{ headerMessage }}
            </div>
            <div class="card-body">
                {{ infoText }}
                <input v-if="isTextInput" class="form-control" @onChange="inputChange" @keydown.enter="confirm" v-model="editValue" />
                <Editor v-if="isTextArea" v-model="editValue" />
                <input v-if="isCosts" type="number" class="form-control" @onChange="inputChange" @keydown.enter="confirm" v-model="editValue" />
            </div>
            <div class="card-footer d-flex justify-content-end">
                <button @click="cancel" class="btn btn-secondary me-1">Abbrechen</button>
                <button v-if="!isCosts" @click="confirm" class="btn btn-primary">Speichern</button>
                <button v-if="isCosts" @click="confirmIncome" class="btn btn-success me-1">
                    <span>Einkommen</span>
                    <span class="ms-1 ">
                        <i class="fa-solid fa-arrow-right"></i>
                        <i class="fa-solid fa-wallet"></i>
                    </span> 
                </button>
                <button v-if="isCosts" @click="confirmOutcome" class="btn btn-danger">
                    <span>Ausgabe</span>
                    <span class="ms-1 ">
                        <i class="fa-solid fa-wallet"></i>
                        <i class="fa-solid fa-arrow-right"></i>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
    name: "DynamicForm",
    data() {
        return {
            editValue: ""
        }
    },
    mounted() {

    },
    methods: {
        cancel(event) {
            this.$emit('onCancel', this.editValue, event);
        },
        confirm(event) {
            this.$emit('onConfirm', this.editValue, event);
        },
        inputChange(event) {
            this.$emit('onChange', this.editValue, event);
        },
        confirmIncome(event) {
            this.$emit('onConfirmIncome', this.editValue, event);
        },
        confirmOutcome(event) {
            this.$emit('onConfirmOutcome', this.editValue, event);
        }
    },
    emits: [
        'onCancel', 'onConfirm', 'onChange', 'onConfirmIncome', 'onConfirmOutcome'
    ],
    computed: {
        isTextInput() {
            if(this.formType == 'text') {
                return true
            }
            return false
        },
        isTextArea() {
            if(this.formType == 'textArea') {
                return true
            }
            return false
        },
        isCosts() {
            if(this.formType == 'costs') {
                return true
            }
            return false
        }
    },
    props: {
        enabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Number],
            default: undefined
        },
        headerMessage: {
            type: String,
            default: "Überschirft"
        },
        infoText: {
            type: String,
            default: ""
        },
        formType: {
            type: String,
            default: 'text'
        }
    },
    watch: {
        enabled() {
            this.editValue = this.value;
        }
    },
    components: {
        Editor
    }
}
</script>

<style lang="scss" scoped>
.backgroundForm {
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    z-index: 1200;
}
</style>