<template>
    <div v-if="!isEdit" class="card text-dark">
        <div class="card-header d-flex justify-content-between">
            <div>
                {{community.name}}
            </div>
            <div>
                <community-item-action :community="community" @openEditMode="isEdit=true" />
            </div>
        </div>
        <div class="card-body">
            <div class="border p-2 rounded">
                <h5 class="mt-1"><i class="fa-solid fa-basket-shopping"></i> Einlaufsliste / Wunschliste</h5>
                <div class="d-flex justify-content-start">
                    <div class="me-1" :key="cart.id" v-for="(cart) of getCommunityCarts">
                        <button @click="goToShoplist(cart)" class="btn btn-outline-secondary">
                            <i class="fa-solid fa-cart-shopping"></i> {{cart.name}}
                        </button>
                    </div>
                </div>
            </div>
            <div class="border p-2 rounded mt-2"  @click="goToFinance">
                <h5 class="mt-1"><i class="fa-solid fa-coins"></i> Finanzen</h5>
                <div>
                    <i class="fa-solid fa-wallet"></i> <span class="ms-1"> {{ getFinance }} €</span>
                </div>
            </div>
        </div>
        <div class="card-footer">
            <i class="fa-solid fa-people-group fa-lg"></i> <span class="bigFontSize ms-2">{{ community.members.length }}</span> 
        </div>
    </div>

<!--- BEARBEITEN MODUS-->


    <div v-if="isEdit" class="card text-dark">
        <div class="card-header d-flex justify-content-between">
            <div>
                <input class="form-control" v-model="editCommunity.name" />
            </div>
            <div>
                
            </div>
        </div>
        <div class="card-body">
            <div class="text-danger" v-if="!isAdmin(user.id)">
                Du bist kein Häuptling und kannst nur die Einstellung einsehen.
            </div>
            <h5 class="mt-3"><i class="fa-solid fa-car"></i> Fahrzeuge</h5>
            <select :disabled="!isAdmin(user.id)" :class="getDisableClass" v-model="editCommunity.vehicleAccess" class="form-select" name="vehicleShare">
                <option value=0>
                    Nicht Teilen
                </option>
                <option value=1>
                    Teilen
                </option>
            </select>
            <h5 class="mt-3"><i class="fa-solid fa-basket-shopping"></i> Einlaufsliste / Wunschliste</h5>
            <div class="input-group mb-3" v-if="isAdmin(user.id)">
               <input @keydown.enter="newCartList" class="form-control" placeholder="Neue Einlaufs oder Wunschliste" v-model="newCartListName" /> <button  @click="newCartList" class="btn btn-success"><i class="fa-solid fa-plus"></i> Hinzufügen</button>
            </div>
            <div :key="cart.id" v-for="(cart) of getCommunityCarts">
                <div class="border rounded mt-1 p-2 d-flex justify-content-between">
                    <div>
                       <input @blur="saveCart(cart)" class="form-control form-control-sm" v-model="cart.name"  />
                    </div>
                    <div class="d-flex">
                        <div v-if="isAdmin(user.id)" @click="removeCart(cart)" class="pointer">
                            <i class="fa-solid fa-trash"></i>
                        </div>
                    </div>
                </div>
            </div>
            <h5 class="mt-3"><i class="fa-solid fa-coins"></i> Finanzen</h5>
        </div>
        <div class="card-footer">
            <button @click="save" class="btn btn-success float-end">Speichern</button>
        </div>
    </div>

</template>

<script>
import FlashMessage from '@/assets/js/messageObjects/flashMessageObject';
import confirmMessage from '@/assets/js/messageObjects/confirmMessageObject';
import Community from '@/assets/model/Community';
import { mapGetters } from 'vuex';
import CommunityItemAction from './CommunityItemAction.vue';
import CartList from '@/assets/model/CartList'

export default {
    data() {
        return {
            editCommunity: {},
            showMemberModal: false,
            isEdit: false,
            newCartListName: ""
        }
    },
    created() {
        this.editCommunity =  this.community;
    },
    computed: {
        ...mapGetters({
            user: 'user/getUser',
            cartItems: 'cart/getList'
        }),
        getDisableClass() {
            if(!this.isAdmin(this.user.id)) {
                return "text-dark transparentBg";
            }
            return ""
        },
        getCommunityCarts() {
            return this.cartItems.filter(obj => (obj.community == '/api/communities/'+this.community.id && obj.type == "/api/cart_list_types/1"))
        },
        getFinance() {
            let money = 0;
            let allCarts = this.cartItems.filter(obj => obj.community == '/api/communities/'+this.community.id)
            if(allCarts == undefined) {
                return money
            }
            for(let cart of allCarts) {
                money += cart.calculateCosts();
            }
            return money
        }
    },  
    methods: {
        openEditMode() {
            this.isEdit = true;
        },
        save() {
            this.isEdit = false;
            this.editCommunity.saveInDb()
        },
        isAdmin(userId) {
            if(this.community.chiefs.find(obj => obj.id == userId)) {
                return true;
            }
            return false
        },
        checkIsAdmin() {
            if(!this.isAdmin(this.user.id)) {
                const flash = new FlashMessage();
                flash.newMessage('danger', 'Du bist leider kein Häuptling')
                flash.run(this.$store)
            }
        },
        newCartList() {
            const cartList = new CartList();
            cartList.setName(this.newCartListName);
            cartList.setCommunity(this.community)
            cartList.saveInDb().then(() => {
                this.newCartListName = ""
                this.$store.dispatch('cart/getLists');
            })

        },
        goToShoplist(cart) {
            this.$store.dispatch('cart/focusCart', cart.id)
            this.$router.push({name: 'cartList'})
        },
        saveCart(cart) {
            cart.saveInDb();
        },
        removeCart(cart) {
            const message = new confirmMessage(this.$store);

            message.newConfirmMessage("Bist du sicher, dass du \""+cart.name+"\" löschen möchtest?", () => {
                cart.delete(this.$store)
            } );
        },
        goToFinance() {
            this.$store.dispatch('community/changeFocus', this.community.id)
            this.$router.push({name: "financeDashboard"})
        },
    },
    props: {
        community: Community
    },
    components: {
        CommunityItemAction
    }
}

</script>

<style lang="scss" scoped>
.bigFontSize {
    font-size: 1.25rem;
}
</style>