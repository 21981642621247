<template>
    <select v-model="editCategory.id" @change="changeCategory" class="form-select">
        <option :key="category.id" :value="category.id" v-for="category in categories">
            {{category.name}}
        </option>
    </select>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: "VehicleCategoryDropdown",
    date() {
        return {
            editCategory: null
        }
    },
    created() {
        this.editCategory = this.selectedCategory;
    },
    methods: {
        changeCategory() {
            this.$emit('changeCategory',this.categories.find(obj => obj.id == this.editCategory.id));
        }
    },
    computed: {
            ...mapGetters({
            categories: 'vehicle/getTransactionCategories'
        })
    },
    props: {
        selectedCategory: {
            type: Object,
            default: () => ({
                id: 1
            }),
            required: false
        }
    }

}
</script>

<style>

</style>