<template>
    <div class="card bg-dark border-dark pointer ">
        <div class="card-body d-flex justify-content-between">
            <div @click="goToDashboard"  >
                {{vehicle.name}} - {{vehicle.licensePlate}}
            </div>
            <div>
                <VehicleItemAction :vehicle="vehicle" />
            </div>
        </div>
        <img @click="goToDashboard"  class="img-fluid cardHeight" v-if="vehicle.mainPicture" :src="getImage(vehicle.mainPicture.id)" />
    </div>

</template>

<script>
import apiConnection from '@/assets/js/apiConnection';
import { mapGetters } from 'vuex';
import VehicleItemAction from './VehicleItemAction.vue';

export default {
    props: {
        vehicle: Object
    },
    computed: {
        ...mapGetters({
            user: 'user/getUser'
        })
    },
    components: {
        VehicleItemAction
    },
    methods: {
        goToDashboard() {
            this.$store.dispatch('vehicle/changeFocusCar', this.vehicle.id).then(() => {
                this.$router.push({name: 'vehicleDashboard' , params: { vehicleId: this.vehicle.id}})         
            })
        },
        getImage(fileId) {
            return apiConnection.getFileLink(fileId);
        }
    },

}
</script>

<style lang="scss" scoped>
    .card {
        
    }

    .cardHeight {
        width: 100%;
        height: 150px;
        object-fit: cover;
    }

/*     img {
        border-radius: 5px;
        -webkit-filter: blur(0.6px) grayscale(100%); /* Safari 6.0 - 9.0 */
     /*    filter: blur(0.6px) grayscale(100%);
        transition: 0.5s;
    }
    img:hover {
        -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
      /*   filter: grayscale(0%);
        -webkit-filter: blur(0px); /* Safari 6.0 - 9.0 */
     /*    filter: blur(0px);
    } */
</style>