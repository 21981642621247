//const uuid = require('uuid');
//import apiConnection from "@/assets/js/apiConnection"
//import helper from "../js/helper";
//import CartProduct from "./CartProduct";


class CartListType {
    
    constructor() {
        this.name = ""
        this.id = null
    }

    setName(name=undefined) {
        if(name != undefined) {
            this.name = name;
        }
        return this.name;
    }

    getName() {
        return this.name;
    }

    openTransportLayer(data) {
        this.name = data.name;
        this.id = data.id
    }
 
}


export default CartListType