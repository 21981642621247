<template>
    <div v-if="cartId">
        <h1>{{cart.name}}</h1>
        <div class="input-group mb-3">
            <input @keyup.enter="newProduct" v-model="newProductName" class="form-control" placeholder="Neuen Artikel z.B. 40g Butter oder 2 Möhren" /> <button @click="newProduct" class="btn btn-success"><i class="fa-solid fa-plus"></i> Hinzufügen</button>
        </div>
        <div v-if="isDebugMode">
            Updatepause: {{ autoUpdatePause }} Sekunden
            <button class="btn btn-primary" @click="addMultipleDatas">Datensatz hinzufügen</button>
        </div>
        <div class="p-1">
            <div :key="product.id" v-for="(product) of getProducts">
                <cart-product-vue :cartProduct="product"  @changeSortIndex="changeSort" @onSaveDb="pauseUpdate" @onRemove="sortIndexList" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CartProduct from '@/assets/model/CartProduct'
import CartProductVue from '@/components/cart/CartProduct.vue';

export default {
    name: "CartList",
    data() {
        return {
            newProductName: "",
            autoUpdatePause: 0
        }
    },
    beforeCreate() {
        this.$store.dispatch('cart/getLists').then(() => {
                this.$store.dispatch('cart/focusCart')
            })

    },
    created() {
        setTimeout(this.loopReload(), 2000);
    },
    computed: {
        ...mapGetters({
            cartId: 'cart/getFocusCartId',
            cartList: 'cart/getList',
            isDebugMode: 'localSetting/getDebugMode'
        }),
        getProducts() {
            if(this.cartId != null) {
                let products = this.cartList.find(obj => obj.id == this.cartId).cartProducts
                //return products
                products = products.sort((a, b) => {
                    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
                    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                    });
                products.sort((a, b) => {
                    return a.sortIndex > b.sortIndex
                }) 
                // products.sort((a, b) => a.isBought - b.isBought)
                return products

            }
            return []
        },
        cart() {
            if(this.cartId != null) {
                return this.cartList.find(obj => obj.id == this.cartId);
            }
            return null
        }
    },
    methods: {
        loopReload() {
            if(this.$route.name != "cartList") {
                return
            }
            if(this.autoUpdatePause > 0) {
                setTimeout(() => {
                    this.autoUpdatePause--
                    this.loopReload();
                },1000)
                return 
            }
            this.$store.dispatch('cart/getLists').then(() => {
                this.$store.dispatch('cart/focusCart').then(() => {
                    setTimeout(() => {
                        this.loopReload();
                    }, 1500);
                }) 
            })

        },
        reload() {
            this.$store.dispatch('cart/getLists').then(() => {
                this.$store.dispatch('cart/focusCart')
            })
        },
        newProduct() {
            const cartProduct = new CartProduct();
            cartProduct.setName(this.newProductName)
            this.newProductName = "";
            cartProduct.setAmount(1);
            cartProduct.sortIndex = this.cartList.find(obj => obj.id == this.cartId).cartProducts.length+2
            cartProduct.setCartList(this.cart)
            cartProduct.saveInDb().then(() => {
                this.reload()
            })
        },
        changeSort(element, direction) {
            this.autoUpdatePause = 3
            let newIndex = element.sortIndex
            if(direction == 'up') {
                newIndex--;
            } else {
                newIndex++;
                let oldProductOnIndex = this.cartList.find(obj => obj.id == this.cartId).cartProducts.find(obj => obj.sortIndex == newIndex)
                oldProductOnIndex.sortIndex--;
                oldProductOnIndex.saveInDb();
            }
            element.sortIndex = newIndex
            element.saveInDb();
            this.cartList.find(obj => obj.id == this.cartId).cartProducts.find(obj => obj.id == element.id).sortIndex = newIndex
            let needUpdateProducts = this.cartList.find(obj => obj.id == this.cartId).cartProducts.filter(obj => (obj.sortIndex >= newIndex && obj.id != element.id))
            let i=1
            for(let updateProduct of needUpdateProducts) {
                updateProduct.sortIndex = newIndex+i;
                updateProduct.saveInDb();
                i++
            }
        },
        pauseUpdate() {
            this.autoUpdatePause = 3
        },
        sortIndexList() {
            setTimeout(() => {
                this.autoUpdatePause = 3
                let needUpdateProducts = this.cartList.find(obj => obj.id == this.cartId).cartProducts
                let i = 1;
                for(let updateProduct of needUpdateProducts) {
                    updateProduct.sortIndex = i;
                    updateProduct.saveInDb();
                    i++
                }
            },3000)
        },
        addMultipleDatas() {
            let productNameBasis = this.newProductName;
            for(let i=0; i<=10; i++ ) {
                this.newProductName = productNameBasis + " " + i;
                this.newProduct()
            }
            setTimeout(() => {
                this.sortIndexList()
            }, 1500)
            
            
        }
    },
    components: {
        CartProductVue
    }
}
</script>

<style scoped lang="scss">
</style>