<template>
    <template v-if="enabled">
        <button class="btn" :class="getBtnColor" @click="isClicked">
            <div class="">
                <span v-if="isSaving">
                    <i class="fa-solid fa-floppy-disk fa-flip"></i>
                </span>
                <span v-if="!isSaving">
                    <i class="fa-solid fa-floppy-disk"></i> {{ txtButton }}
                </span>
                <span v-if="txtButton">
                    {{ txtButton }}
                </span>
            </div>
        </button>
    </template>
</template>

<script>
import FlashMessage from '@/assets/js/messageObjects/flashMessageObject';



export default {
    name: "SaveButton",
    data() {
        return {
            isSaving: false
        }
    },
    mounted() {

    },
    methods: {
        isClicked() {
            if(this.isSaving == true) {
                const flash = new FlashMessage()
                flash.newMessage("warning", 'Aktion läuft bereits im Hintergrund.')
                return;
            }
            const answer = this.confirmFunction()
            if (typeof answer.then !== "undefined") { 
                answer.then(() => {
                    this.isSaving = false
                }) 
            }
            this.$emit('onClick', this)
            this.isSaving = true;
        },
        stopSaving() {
            this.isSaving = false;
        }
    },
    emits: [
        'onClick'
    ],
    computed: {
        getBtnColor() {
            if(this.saveError) {
                return "btn-outline-danger"
            } else {
                return "btn-outline-success"
            }
        }
    },
    props: {
        enabled: {
            type: Boolean,
            default: true
        },
        txtButton: {
            type: String,
            default: ""
        },
        saveError: {
            type: Boolean,
            default: false
        },
        confirmFunction: {
            type: Function,
            default: () => {}
        }
    },
    watch: {
        enabled() {

        },
        saveError(newValue) {
            if(newValue == true) {
                this.isSaving = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>