<template>
    <h1>Login</h1>

    <div v-if="error" class="alert alert-danger mt-2">{{error}}</div>
    <label class="form-label">Email</label>
    <input @keydown.enter="login" class="form-control"  v-model="userValues.email" />
    <label class="form-label mt-2">Passwort</label>
    <input @keydown.enter="login" class="form-control" type="password"  v-model="userValues.password" />
    <button @click="login" class="btn btn-primary mt-4 w-100"><span ><i class="fa-solid fa-right-to-bracket"  :class="getLogginAnimation"></i></span> Einloggen</button>


    
<!--     <form action="http://localhost/login" method="POST">
        <input name="email" />
        <input name="password" type="password" />
        <input type="submit" value="Einloggen" />
    </form> -->
</template>

<script>

export default {
    data() {
        return {
            userValues: {
                email: "",
                password: ""
            },
            error: "",
            isLogginIn: false
        }
    },
    created() {
        
    },
    methods: {
        login() {
            this.isLogginIn = true;
            this.$store.dispatch('user/login', this.userValues).then(() => {
                this.isLogginIn = false;
                this.$router.push({name: 'home'})
            }).catch(error => {
                console.log(error)
                if(error.response.data) {
                    this.error = error.response.data;
                } else {
                    this.error = "Unbekannter Fehler"
                }
            })
        }
    },
    computed: {
        getLogginAnimation() {
            if(this.isLogginIn) {
                return "fa-beat fa-rotate-180";
            }
            return ""
        }
        
    }

}
</script>