<template>
<div v-if="isAvailable">
    <div v-if="showPreview" @click="closePreview" class="fullScreenShow fixed-top">
        <div @click.stop id="toolBox" class="border-bottom border-primary">
            <div class="container p-3">
                <div class="input-group">
                    <label class="input-group-text text-dark">Dateinamen</label>
                    <input @keydown.enter="save" v-model="editImage.baseName" class="form-control" />
                </div>
                <div class="float-end mt-1">
                    <button @click="save" class="txBtn txBtn-success">Speichern</button>
                </div>
            </div>
        </div>
        <div @click="closePreview" class="container p-3 d-flex">
            <div class="imageWindow m-auto">
                <img class="w-100 " :src="getImagePath(image.id)" />
            </div>
        </div>
    </div>
    <div class="card">
    <!--     <div class="card-header text-dark">
            {{image.name}}
        </div> -->
        <div v-if="!removingRunning" @click="openPreview" class="card-image pointer">
            <img class="w-100 img-fluid img-thumbnail" :src="getImagePath(image.id)" />
        </div>
        <div  v-if="removingRunning" class="card-body text-dark m-auto">
            <i class="fas fa-spinner fa-spin fa-2x"></i>
        </div>

        <div class="card-footer d-flex justify-content-between">
            <div>
                <span class="text-dark" v-if="isMainPicture">Hauptbild</span>
            </div>
            <div class="d-flex justify-content-end">
                <div v-if="(isMainable && !isMainPicture)" @click="setMainImage" class="pointer text-dark me-1">
                    <i class="fa-solid fa-arrows-to-eye fa-lg"></i>
                </div>
                <div @click="askForRemove" class="pointer  text-danger">
                    <i class="fa-solid fa-trash fa-lg"></i>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>    
import apiConnection from '@/assets/js/apiConnection';

export default {
    name: "ImageCard",
    emits: ['removedFile', 'setMainImage', 'unlinkMainImage'],
    data() {
        return {
            removingRunning: false,
            showPreview: false,
            isMainPicture: false,
            editImage: null,
            isAvailable: true
        }
    },
    created() {
        this.editImage = this.image;
    },
    mounted() {
        if(this.mainPicture === null) {
            return;
        }
        
        if(this.mainPicture.id == this.image.id) {
            this.isMainPicture = true
        }
    },
    methods: {
        setMainImage() {
            this.isMainPicture = true;
            this.$emit('setMainImage', this.image);
        },
        unlinkMainImage() {
            this.$emit('unlinkMainImage', this.image);
        },
        askForRemove() {
            if(this.isMainPicture) {
                let message = {}
                message.message = "Diese Bild wird derzeit als ein Hauptbild verwendet. Daher kann es nicht gelöscht werden.";
                this.$store.dispatch('system/startNewInfo', message)
                return
            }
            let message = {}
            message.message = "Bist du sicher dass du das Bild " + this.image.name + " löschen möchtest?";
            message.confirmFunction = this.removeItem;
            this.$store.dispatch('system/startNewConfirm', message)
        },
        removeItem() {
            this.removingRunning = true;
            this.$store.dispatch('file/removeFile', this.image.id).then(() => {
                this.isAvailable = false;
                this.$emit('removedFile')
            })
        },
        save() {
            this.$store.dispatch('file/updateFile', this.editImage).then(() => { 
                this.$emit('savedFile')
            })
            
        },
        openPreview() {
            this.showPreview = true
        },
        closePreview() {
            this.showPreview = false
        },
        getImagePath(fileId) {
            let link = apiConnection.getFileLink(fileId)
            return link
        }
    },
    props: {
        image: Object,
        isMainable: {
            type: Boolean,
            default: false
        },
        mainPicture: {
            type: Object,
            default: () => ({
                id: 0
            })
        }
    },

}
</script>

<style lang="scss" scoped>
    .fullScreenShow {
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.8);
    }

    .imageWindow {
        max-width: 400px;
        max-height: 800px;
    }

</style>