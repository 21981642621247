const uuid = require('uuid');
import apiConnection from "@/assets/js/apiConnection"
import helper from "../js/helper";


class Community {
    
    constructor() {
        this.members = []
        this.chiefs = []
        this.invitations = []
        this.confirmInvitation =  ""
        this.invitationAttempts = []
        this.id = null
    }

    name(name=undefined) {
        if(name != undefined) {
            this.name = name;
        }
        return this.name;
    }

    /**
     * 
     * @param {string} user 
     */
    addMember(user) {
        if(!this.members.find(obj => obj == user)) {
            this.members.push(user);
        }
        return this.members;
    }

    removeMember(user) {
        console.log(user)
        let index = this.members.findIndex(obj => obj.id == user.id);
        if(index < 0) {
            return
        }
        this.members.splice(index,1);
    }

    setMembers(users) {
        this.members = users;
        return this.members;
    }

    getMembers() {
        return this.members;
    }

    /**
     * 
     * @param {string} user 
     */
         addChief(user) {
            if(!this.chiefs.find(obj => obj == user)) {
                this.chiefs.push(user);
            }
            return this.chiefs;
        }
    
        removeChief(user) {
            let index = this.chiefs.findIndex(obj => obj.id == user.id);
            if(index < 0) {
                return
            }
            this.chiefs.splice(index,1);
        }
    
        setChiefs(users) {
            this.chiefs = users;
            return this.chiefs;
        }
    
        getChiefs() {
            return this.chiefs;
        }

    addInvitationAttemt(emailAddress) {
        let invitation = {
            id: uuid.v4(),
            email: emailAddress,
            isMailSend: false,
            isUserInDB: true,
            userId: null
        }
        this.invitationAttempts.push(invitation);
    }

    removeInvitationAttemt(emailAddress) {
        let index = this.invitationAttempts.findIndex(obj => obj.email == emailAddress);
        if(index < 0) {
            return
        }
        console.log(index);
        this.invitationAttempts.splice(index,1);
    }

    removeInvitation(user) {
        let index = this.invitations.findIndex(obj => obj.id == user.id);
        if(index < 0) {
            return
        }
        this.invitations.splice(index, 1);
    }

    setConfirmInvitation(user) {
        this.confirmInvitation = user.email
    }

    openTransportLayer(data) {
        this.name = data.name;
        this.setMembers(data.members);
        this.setChiefs(data.chiefs);
        this.id = data.id
        this.invitationAttempts = data.invitationAttempts
        this.invitations = data.invitations
        this.vehicleAccess = data.vehicleAccess
    }

    getTransportLayer() {
        const data = {
            name: this.name,
            '@id': '/api/communities/'+this.id,
        }
        console.log(this.members);
        if(this.members !== undefined) {
            data.members = helper.buildIriArray(this.members, '/api/users/')
        }
        if(this.chiefs != undefined) {
            data.chiefs = helper.buildIriArray(this.chiefs, '/api/users/')
        }
        if(this.invitations != undefined) {
            data.invitations = helper.buildIriArray( this.invitations , '/api/users/')
        }
        if(this.invitationAttempts != undefined) {
            data.invitationAttempts = this.invitationAttempts
        }
        if(this.vehicleAccess != undefined) {
            data.vehicleAccess = Number.parseInt(this.vehicleAccess)
        }
        data.confirmInvitation = this.confirmInvitation
        console.log(data)
        return data
    }

    saveInDb() {
        let request = apiConnection.patch('/api/communities/'+this.id, this.getTransportLayer());
        request.then((response) => {
            this.openTransportLayer(response.data)
        })
        return request
    }
}


export default Community