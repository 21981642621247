<template>
   <div class="box m-1 rounded p-2 text-dark" @click="clickElement">
        <div class="d-flex justify-content-between ps-1 pe-1">
            <div>
                <span v-if="isDebugMode">Index: {{ financePosition.sortIndex }}</span>
                <span v-if="multiSelectionMode">
                    <span v-show="isSelected">
                        <i class="fa-regular fa-circle-check"></i>
                    </span>
                    <span v-show="!isSelected">
                        <i class="fa-regular fa-circle"></i>
                    </span>
                </span>
                {{ financePosition.name }}
                <DynamicForm :enabled="nameEdit" 
                    header-message="Artikelname"
                    :value="financePosition.name"
                    @on-confirm="changeName"
                    @on-cancel="resetView" />
            </div>
            <div v-if="!multiSelectionMode" @click="openActionMenu">
                <i class="fa-solid fa-ellipsis fa-lg"></i>
            </div>
            <div v-if="multiSelectionMode" @click.stop>
                <span  v-show="!isFirstPosition" @click="changeSort('up')">
                    <i class="fa-solid fa-arrow-up"></i>
                </span>
                <span v-show="!isLastPosition" @click="changeSort('down')" class="ms-2">
                    <i class="fa-solid fa-arrow-down"></i>
                </span>
            </div>
        </div>
        <hr class="m-1">
        <div class="ps-1 pe-1 d-flex justify-content-between" :class="getStyleClassByIncome">
            <div>{{ getAmount }} {{ getSingleValue }}</div>
            <div>{{ getSumValue }}</div>
        </div>
    </div>

    <DynamicForm :enabled="amountEdit" 
                    header-message="Menge"
                    :value="financePosition.amount"
                    @on-confirm="changeAmount"
                    @on-cancel="resetView" />

    <DynamicForm :enabled="valueEdit" 
    header-message="Preis / Kosten"
    form-type="costs"
    :value="financePosition.value"
    @on-confirm-income="changeValueIncome"
    @on-confirm-outcome="changeValueOutcome"
    @on-cancel="resetView" />
</template>

<script>
import ActionDialog from '@/assets/js/messageObjects/ActionDialogObject';
import confirmMessage from '@/assets/js/messageObjects/confirmMessageObject';
import CartProduct from '@/assets/model/CartProduct';

import { mapGetters } from 'vuex'
import DynamicForm from '../general/DynamicForm.vue';


export default {
    name: "FinancePosition",
    emits: ["changeSortIndex", "onSaveDb", "onRemove", 'onClick', 'changeSort'],
    data() {
        return {
            nameEdit: false,
            amountEdit: false,
            valueEdit: false
        };
    },
    created() {
    },
    computed: {
        ...mapGetters({
            user: "user/getUser",
            cartId: "cart/getFocusCartId",
            cartList: "cart/getList",
            focusCart: "cart/getFocusCart",
            mousePosition: "system/getMousePosition",
            isDebugMode: "localSetting/getDebugMode"
        }),
        getAmount() {
            if (!this.financePosition.amount) {
                return "0 x";
            }
            return this.financePosition.amount + " x";
        },
        getSingleValue() {
            if (!this.financePosition.value) {
                return "0.00 €";
            }
            return this.financePosition.value + " €";
        },
        getSumValue() {
            if(this.financePosition != undefined) {
                return this.financePosition.getSumPrice() + " €";
            }
            return 0.0
        },
        isIncome() {
            if(this.financePosition.value >= 0) {
                return true
            }
            return false
        },
        getStyleClassByIncome() {
            if(this.isIncome) {
                return ""
            }
            return "text-danger"
        },
        isFirstPosition() {
            if(this.financePosition.sortIndex == 1) {
                return true
            }
            return false
        },
        isLastPosition() {
            if(this.financePosition.sortIndex == this.focusCart.cartProducts.length) {
                return true
            }
            return false
        }
    },
    methods: {
        resetView() {
            this.nameEdit = false;
            this.amountEdit = false;
            this.valueEdit = false;
        },
        openActionMenu(event) {
            const dialog = new ActionDialog(event);
            dialog.setHeaderText(this.financePosition.name + " - Menü")
            dialog.addButton("Name ändern", () => {
                this.nameEdit = true;
            });
            dialog.addButton("Preis anpassen", () => {
                this.valueEdit = true
            })

            dialog.addButton("Setze als Ausgabe", () => {
                this.financePosition.setAsOutcome()
                this.financePosition.saveDB()
                this.focusCart.calculateCosts()
            },'text-danger')
            dialog.setButtonCondition(this.isIncome)

            dialog.addButton("Setze als Einkommen", () => {
                this.financePosition.setAsIncome()
                this.financePosition.saveDB()
                this.focusCart.calculateCosts()
            },)
            dialog.setButtonCondition(!this.isIncome)

            dialog.addTopic('Menge: ','', this.financePosition, 'amount');

            dialog.addButton("Menge ändern", () => {
                this.amountEdit = true
            })
            dialog.addButton("Menge +1", () => {
                this.financePosition.setAmount(parseFloat(this.financePosition.amount) +1 )
                this.$forceUpdate();
                this.financePosition.saveDB();
            })

            dialog.setCloseAfterClick(false)
            dialog.addButton("Menge -1", () => {
                this.financePosition.setAmount(this.financePosition.amount -1 )
                this.financePosition.saveDB();
            })
            dialog.setCloseAfterClick(false)

            dialog.addHr();
            
            dialog.addButton("Position löschen", () => {
                const confirm = new confirmMessage
                confirm.setMessage("Bist du sicher, dass du "+ this.financePosition.name + " löschen möchtest?")
                confirm.setConfirmText("Ja")
                confirm.setConfirmFunction(() => {
                    this.deletePosition();
                })
                confirm.run();
            }, 'text-danger')

            dialog.run();
        },
        changeName(value) {
            this.financePosition.setName(value);
            this.financePosition.saveInDb();
            this.resetView()
        },
        changeAmount(value) {
            this.financePosition.setAmount(value)
            this.financePosition.saveInDb();
            this.resetView()
        },
        changeValueIncome(value) {
            this.financePosition.setValue(value)
            this.financePosition.setAsIncome()
            this.financePosition.saveDB()
            this.resetView()
            this.focusCart.calculateCosts()
        },
        changeValueOutcome(value) {
            this.financePosition.setValue(value)
            this.financePosition.setAsOutcome()
            this.financePosition.saveDB()
            this.resetView()
            this.focusCart.calculateCosts()
        },
        deletePosition() {
            this.financePosition.delete(this.$store,this.focusCart)
        },
        clickElement() {
            this.$emit('onClick', this.financePosition)
        },
        changeSort(direction) {
            this.$emit('changeSort', this.financePosition, direction)
        }
    },
    props: {
        financePosition: CartProduct,
        multiSelectionMode: Boolean,
        isSelected: {
            type: Boolean,
            default: false
        },
    },
    components: { DynamicForm }
}
</script>

<style lang="scss" scoped>
.box {
    background-color: rgba(240, 240, 240, 0.814);
}
</style>