<template>
    <div id="actionDialogNavi" @mouseleave="closeMenu" v-if="enabled" class="actionDialogNavi text-dark" >
        <div class="shadow card">
            <div class="card-header bg-dark text-light ">
                {{ params.headerText }}
            </div>
            <template :key="index" v-for="(button, index) of params.buttonItems">
                <template v-if="button.show">
                    <div v-if="button.type=='button'" @click="buttonClicked(button)" class="card-footer" :class="button.textStyle">
                    {{ button.buttonName }}
                    </div>
                    <div v-if="button.type=='topic'" class="card-header bg-dark text-light " :class="button.textStyle">
                        {{ button.buttonName }} <template v-if="button.viewObject">{{ button.viewObject[button.viewValue] }}</template> 
                    </div>
                    <div v-if="button.type=='hr'" class="bg-dark smallHeight" :class="button.textStyle">
    
                    </div>
                </template>
            </template>
        </div>
    </div>
    <div @click="closeMenu" v-if="enabled" class="bgForClickEvent">
    </div>
</template>

<script>
//import flashMessage from '@/assets/js/messageObjects/flashMessageObject';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            widthPos: 0,
            showMemberModal: false
        }
    },
    emits: ['openEditMode'],
    mounted() { 
        
    },
    methods: {
        closeMenu() {
            this.$store.dispatch('system/setActionDialog', {
                enabled: false
            })
        },
        buttonClicked(button) {
            button.buttonAction();
            if(button.closeAfterClick) {
                this.closeMenu();
            }
        }
    },
    computed: {
        ...mapGetters({
            'user': 'user/getUser'
        })
    },
    props: {
        enabled: {
            type: Boolean,
            default: false
        },
        params: Object
    },
    watch: {
        enabled(value) {
            if(!value) {
                return
            }
            this.$nextTick(() => {
                let menuElement = document.getElementById('actionDialogNavi');
                let windowWidth = document.documentElement.clientWidth;
                let leftPos = this.params.event.clientX
                let yStyle  = "top:"+ this.params.event.layerY+"px;";

                let stylePosition = "position: absolute;";
                if(windowWidth < (this.params.event.pageX+300)) {
                    leftPos -=  300;
                }
                // Wenn es ein kleiner Monitor ist wird automatisch die Eingabe zentriert
                this.widthPos = windowWidth
                if(windowWidth < 400) {
                // let borderWidth = windowWidth-300;
                    leftPos = 0//borderWidth/2;
                    stylePosition = "position: fixed;"
                    yStyle = "top:"  + 0 +   "px;width:"+windowWidth+"px;";
                }
                menuElement.style = stylePosition + yStyle+'left:'+ (leftPos)+'px'
            })
        }
    }
}
</script>

<style lang="scss" scoped>
    
    .actionDialogNavi {
        position: absolute;
        background-color: white;
        width: 300px;
        z-index: 2000;
        border-radius: 3px;
    }

    .card-footer:hover {
        background: rgba($color: #000000, $alpha: 0.2);
        cursor: pointer;
    }

    .bgForClickEvent {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
    }

    .smallHeight {
        height: 2px;
    }
</style>

