<template>
    <div v-if="user">
        <div v-if="user.communitieInvitations.length > 0">
            <div :key="invitation.id" class="alert alert-success" v-for="(invitation) of user.communitieInvitations">
                <div class="d-flex justify-content-between">
                    <div>
                        Hey mega! Du hast eine neue Gemeinschaftanfrage erhalten! Möchtest du <b>{{ invitation.name }}</b> beitreten?
                    </div>
                    <div class="pointer" @click="toggleMembers(invitation)">
                        Zeige Mitglieder <i v-if="!isDetailOpen" class="fa-solid fa-caret-down"></i><i v-if="isDetailOpen" class="fa-solid fa-caret-up"></i>
                    </div>
                </div>
                <hr>
                <div v-if="invitation.showMember">
     
                    <div :key="member.id" v-for="(member) of invitation.members">
                        {{ member.name }} - {{ member.email }}
                    </div>
                </div>
                <div class="mt-3 d-flex justify-content-end">
                    <button class="btn btn-danger me-3"><i class="fa-solid fa-ban"></i> Ablehnen</button>
                    <button @click="acceptInvitation(invitation)" class="btn btn-success"><i class="fa-solid fa-check"></i> Annehmen</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Community from '@/assets/model/Community';
import { mapGetters } from 'vuex'

export default {
    created() {
    },
    computed: {
        ...mapGetters({
            user: 'user/getUser'
        })
    },
    methods: {
        toggleMembers(invitation) {
            invitation.showMember = !invitation.showMember;
        },
        acceptInvitation(invitation) {
            const community = new Community()
            community.openTransportLayer(invitation);
            community.setConfirmInvitation(this.user)
            community.saveInDb().then(() => {
                this.$store.dispatch('user/getUser')
                this.$store.dispatch('community/getAll')
            })

        }
    }
}
</script>