<template>
<header-image :headerText="getDashboardHeadetText" :imageLink="require('@/assets/files/vehicle/car_cockpit.jpg')" />
<button @click="goToVehicleList" class="d-none d-md-inline txBtn txBtn-primary">Zur Fahrzeugliste</button>
<div v-if="vehicle">
    <div class="row mt-1">
        <div class="col-12 col-sm-12  col-md-4 col-lg-3">
            <vehicle-item :vehicle="vehicle" />
<!--             <div class="card mt-1 bg-dark d-flex align-items-center">
                <div class="card-text text-center firstLetter-primary">
                    <h5>{{vehicle.name}} - {{vehicle.licensePlate}}</h5>
                    <div>

                    </div>
                </div>
                <div class="card-image">
                    <img class="img-fluid rounded" :src="getMainPicture" />
                </div>
            </div> -->
        </div>
        <div class="col-6 col-sm-6  col-md-4 col-lg-3">
            <div @click="goToRefuel" class="card mt-1 bg-dark d-flex align-items-center pointer">
                <div class="card-text text-center firstLetter-primary">
                    <h5>Jetzt Tanken</h5>
                </div>
                <div class="card-image">
                    <img class="img-fluid rounded" src="@/assets/files/vehicle/gas-pump.jpg" />
                </div>
            </div>
        </div>
        <div class="col-6 col-sm-6  col-md-4 col-lg-3">
            <div @click="goToAutoCare" class="card mt-1 bg-dark d-flex align-items-cente pointer">
                <div class="card-text text-center firstLetter-primary">
                    <h5>Autopflege</h5>
                </div>
                <div class="card-image">
                    <img class="img-fluid rounded" src="@/assets/files/vehicle/cleaning.jpg" />
                </div>
            </div>
        </div>
        <div v-if="getLastTransaction" class="col-12 col-md-4 col-lg-3">
            <div class="card mt-1 bg-dark d-flex align-items-center">
                <div class="card-text text-center firstLetter-primary">
                    <h5>Letzte Rechnung</h5>
                </div>
                <div class="card-image">
                    <img class="img-fluid rounded" src="@/assets/files/vehicle/bill.jpg" />
                </div>
                <div class="w-100 m-0 p-0">
                    <vehicle-transaction-card :vehicle="vehicle" :isNewCard="false" :transaction="getLastTransaction" />
                </div>
                <div class="card-footer">
                    <button @click="goToTransaction" class="txBtn txBtn-primary">Zeige alle</button>
                </div>
            </div>
        </div>
        <div v-if="getLastTransaction"  class="mt-1 col-12 col-6 col-md-4 col-lg-3">
            <div  class="card bg-dark ">
                <div class="card-text text-center firstLetter-primary">
                    <h5>Kosten</h5>
                </div>
                <div class="card-image">
                    <canvas ref="costsChart" ></canvas>
                </div>
                <div class="card-footer">
                    <button @click="goToAnalyse" class="txBtn txBtn-primary">Zur vollständigen Analyse</button>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-4 col-lg-3">
            <div  @click="goToVehicleList" class="card mt-1 bg-dark">
                <div class="card-text text-center firstLetter-primary">
                    <h5>Garage</h5>
                </div>
                <div class="card-image">
                    <img class="img-fluid rounded" src="@/assets/files/vehicle/garage.jpg" />
                </div>
                <div class="card-footer">
                    <button class="txBtn txBtn-primary">Gehe zu deiner Garage</button>
                </div>
            </div>
        </div>
    </div>
</div>


</template>


<script>
import { mapGetters } from 'vuex';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
import Calculator from '@/assets/js/vehicleCostCalculator'
import VehicleTransactionCard from '../../components/vehicle/VehicleTransactionCard.vue';
import VehicleItem from "../../components/vehicle/VehicleItem.vue"
import HeaderImage from '../../components/general/HeaderImage.vue'

export default {
  components: { VehicleTransactionCard, VehicleItem, HeaderImage },
    name: "VehicleDashboard",
    data() {
        return {
        }
    },   
    beforeCreate() {
        this.$store.dispatch('vehicle/getVehicleItems')
        this.$store.dispatch('vehicle/getTransactions')
        this.$store.dispatch('vehicle/getAllTransactionCategories')
        this.$store.dispatch('vehicle/getAllFuelTypes')
    },
    created() {
        this.$store.dispatch('vehicle/changeFocusCar').then(() => {
            if(this.focusCarId == null) {
                this.goToVehicleList();
            }
        }) 
    },
    mounted() {
        window.scroll(0, 0);
        this.generateChart();
    },
    computed: {
        ...mapGetters({
            vehicleItems: 'vehicle/getVehicleItems',
            transactions: 'vehicle/getTransactions',
            focusCarId: 'vehicle/getFocusCar'
        }),
        getDashboardHeadetText() {
            let string = "Dashboard";
            if(this.vehicle) {
                string = string + " " + this.vehicle.name
            }
            return string
        },
        vehicle() {
            return this.vehicleItems.find(obj => obj.id == this.focusCarId);
        },
        getMainPicture() {
            if(this.vehicle.mainPicture != null) {
                return 'api/v1/file/getFile/'+this.vehicle.mainPicture.id
            }
            return ''

        },
        getLastTransaction() {
            let vehicleTransactions = this.transactions.filter(obj => obj.vehicle.id ==  this.focusCarId)
            if( vehicleTransactions < 1) return null
            vehicleTransactions.sort(function(a,b){
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return  new Date(a.created) - new Date(b.created);
            });
            return vehicleTransactions[vehicleTransactions.length-1]
        }
    },
    methods: {
        goToVehicleList() {
            this.$router.push({name: 'vehicleList'});
        },
        generateChart() {
            if(this.transactions.length <1) return
            Calculator.init(this.transactions.filter(obj => obj.vehicle.id == this.vehicle.id));
            let ctx = this.$refs.costsChart
            let data = {
                labels: [
                    'Sprit',
                    'Inspektion & Reperatur',
                    'Versicherung & Steuern',
                    'Fahrzeug & Zubehör',
                    'Autopflege'
                ],
                datasets: [{
                    label: [
                        'MyFirstDataset'
                    ],
                    data: [Calculator.getCategoryCosts(1), 
                        Calculator.getCategoryCosts(2) + Calculator.getCategoryCosts(3) ,
                        Calculator.getCategoryCosts(4) + Calculator.getCategoryCosts(5),
                        Calculator.getCategoryCosts(6),
                        Calculator.getCategoryCosts(7)],
                    backgroundColor: [
                    '#ff002b',
                    '#ff00ee',
                    '#8c00ff',
                    '#0033ff',
                    '#00d9ff'
                    ],
                    hoverOffset: 4
                }]
            }

            const options = {
                    color: '#dddddd',
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: (ttItem) => {
                                    return `${ttItem.label}: ${ttItem.formattedValue }  €`;
                                } 
                            }
                        },
                        legend: {
                            align: 'start',
                            display: false,
                            position: 'bottom',
                            labels: {
                                textAlign: 'left',
                                usePointStyle: false,
                                font: {
                                    size: 14
                                }
                            }
                        }
                    },
                    layout: {
                        padding:5
                    }
            }

            const CostChart = new Chart(ctx, {
                    type: 'bar',
                    data: data,
                    options: options
            })
            CostChart
        },
        goToTransaction() {
            this.$router.push({name: 'vehicleTransaction', params: {vehicleId: this.vehicle.id }})
        },
        goToRefuel() {
            this.$router.push({name: 'refuelForm'})
        },
        goToAutoCare() {
            this.$router.push({name: 'transactionForm', params: {transactionId: 0, categoryId: 7}})
        },
        goToAnalyse() {
            this.$router.push({name: 'vehicleAnalyse'})
        }
    }
}
</script>

<style lang="scss" scoped>
canvas {
   width: 90%;
   height: auto;
   display:block;
}

</style>