import apiConnection from "@/assets/js/apiConnection";

const state = {
}


const mutations = {
}

const actions = {
    removeFile(commit, imageNumber) {
        let request = apiConnection.delete('/apiController/file/deleteFile/'+imageNumber);
        return request;
    },
    updateFile(commt, image) {
        console.log(image);
        let request = apiConnection.patch('/apiController/file/updateFile/'+image.id, image);
        return request;
    } 
}

const getters = {

}


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};